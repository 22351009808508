@import "../../assets/styles/utilities/variables";

.navbar-toggler {
  background-color: transparent;
  background-image: none;
  border-radius: 3px;
  margin: 23px 25px 23px 0;
  padding: 9px 10px;
  position: absolute;
  right: 0;

  &:focus {
      outline: 0;
    }

  .icon-bar {
    background: $white;
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
  }

  .icon-bar + .icon-bar {
    margin-top: 4px;
  }

  .navbar-toggler-icon {
    background-image: none;
  }

}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 18px;
}

.navbar-btn {
  margin-top: 20px;
  margin-bottom: 20px;
}
.navbar-btn.btn-sm, .btn-group-sm > .navbar-btn.btn {
  margin-top: 26px;
  margin-bottom: 26px;
}

.navbar-btn.btn-xs, .btn-group-xs > .navbar-btn.btn {
  margin-top: 29px;
  margin-bottom: 29px;
}

.navbar-text {
  margin-top: 31px;
  margin-bottom: 31px;
}

@media (max-width: 768px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .navbar-nav .open .dropdown-menu > li > a,
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 18px;
  }

  .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none;
  }
}

@media (min-width: 769px) {
  .navbar-text {
    float: left;
    margin-left: 25px;
    margin-right: 25px;
  }

  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    float: right !important;
    margin-right: -25px;

    ~ .navbar-right {
      margin-right: 0;
    }
  }
  .navbar-toggler {
    display: none;
  }

  .navbar-nav > li {
    float: left;

    > a {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

#navbar-container .navbar-collapse .navbar-nav a.nav-link  {
  font-weight: 300;
  text-transform: uppercase;
}

#navbar-container .navbar-collapse .navbar-nav ul a.nav-link  {
  text-transform: capitalize;
}

#navbar-container .navbar-collapse .navbar-nav a.nav-link:hover {
  color: $white;
}