.browse {
    display: none;
  }
  
  .ouic-drop-area {
    background: #1C222C;
  }
  
  .ouic-drag-and-drop {
    width: 100%;
    border: 1.5px dashed #434C5C;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    padding: 55px 20px;
    margin-bottom: 2em;
    transition: 300ms all ease-in-out;
    background: #1C222C;
    z-index: 9999;
  
    label {
      color: #fff;
      text-decoration: underline;
      font-size: 1em;
    }
  
    p {
      margin: 0;
  
      &:first-of-type {
        padding-top: 0;
        padding-bottom: 20px;
      }
    }
  
    .mb-limit {
      margin-left: 8px;
    }
  
    &.dragging {
      border: 1.5px dashed #1F72F2;
      background-color: #1C2633;
      transition: 300ms all ease-in-out;
      box-shadow: 0 0 30px -20px #000;
    }
  
    &.dropped {
      padding: 10px 20px;
      transition: 300ms all ease-in-out;
      border: 1px dashed #434C5C;
      display: flex;
      justify-content: center;
      align-items: center;
  
      svg {
        margin-right: 10px;
      }
  
      p {
        margin-left: .25rem;
        padding: 0;
        transition: 200ms all ease-in, 300ms max-height ease-out;
        > * {
          display: inline-block;
          margin: 0;
        }
      }
  
      .mb-limit {
        margin-left: 10px;
      }
  
      &.dragging {
        border: 1px dashed #1F72F2;
        background-color: #1C2633;
        transition: 200ms all ease-in-out;
      }
    }

    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }