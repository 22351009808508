@import '../../assets/styles/utilities/variables';

header {
  nav {
    background: $black;
  }
}

#navbar-container {
  background: $black;
  display: flex;
  flex-direction: row;
  flex-flow: row nowrap;
  justify-content: flex-start;
  position: relative;

  .navbar-collapse {
    align-items: center;
    display: flex;
    flex-basis: auto;
    flex-grow: 1;
    overflow-x: visible;
    padding-right: 25px;
    padding-left: 25px;

    .collapse {
      display: flex;
    }
    .navbar-right {
      display: flex;
    }
    .navbar-nav {
      > * {
        padding: 0 15px;
      }

      .nav-link {
        text-decoration: none;
        color: $white;

        &:hover {
          color: $main-blue;
        }

        .icon:before {
          font-family: inherit;
        }

        .ouic-badge {
          height: 14px;
          margin-left: -7px;
          min-width: 14px;
          position: absolute;
          top:-10px;
        }
      }
    }
  }
}

a > span.caret {
  display: none;
}

.btn-custom {
  background-color: transparent;
  border: none;
  color: $white;
  font-size: 12px;
  font-weight: 500;
}

.btn-custom:hover,
.btn-custom:active {
  color: #e6e6e6;
  text-decoration: none;
}

i.icon,
i.nav--icon {
  color: #8e98ac;
  font-size: 2.1em;
}

.navbar-nav {
  flex-direction: row;
  flex-flow: row wrap;
  align-items: center;
}

.icon:hover {
  color: $grey-9b;
}

.nav .icon {
  padding-bottom: 10px;
}

.navbar-collapse .nav.navbar-right > li > a {
  padding: 28px 15px 0;
}

.navbar-nav > li > .dropdown-menu {
  margin: -3px 11px 0 0;
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.navbar-fixed-bottom {
  .navbar-nav > li > .dropdown-menu {
    margin-bottom: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

p.navbar-text {
  font-family: $libre;
  margin: 0;
}

.navbar__item-name > a > p {
  font-weight: normal;
}

.navbar-nav.navbar-right > li > a {
  text-transform: none;
}

.navbar-nav.navbar-right li.navbar__item--padding-top > a {
  padding-top: 35px;
}

.navbar-nav.navbar-right li.navbar__item-name > a {
  padding-top: 22px;
}

a.navbar-brand {
  margin: 0 0 3px 15px;
  padding: 0;
}

.messages .dropdown-toggle {
  position: relative;
}

.messages .unread-message-dot {
  position: absolute;
  border: 2px solid #2b384f;
  border-radius: 100%;
  background-color: $red;
  height: 17px;
  right: 12px;
  width: 17px;
}

@media (max-width: 768px) {
  #navbar-container .navbar-collapse.collapse {
    display: none;
  }

  #navbar-container .navbar-collapse.collapse.show {
    background: $black;
    display: flex !important;
    left: 0;
    margin: 0;
    padding-bottom: 20px;
    position: absolute;
    right: 0;
    top: 80px;
    width: 100%;
    z-index: 99;
  }
}

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none;

    .collapse {
      display: flex;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;
    }
  }
}

@media (min-width: 1801px) {
  #navbar-container {
    padding-right: 30px;
    padding-left: 30px;
  }
}
