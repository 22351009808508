@import '../utilities/variables';

header {
    #navbar-container {
        background: $bg-header;
    }

    .navbar-text {
        color: $white;
    }

    .message-dropdown {
        box-shadow: $box-shadow;

        &__item {
            background: $white;
        }
    }

    .ouic-card {
        background: $white;
    }

    .user-menu {
        .ouic-card {
            box-shadow: $box-shadow;

            ul li > a,
            ul li:nth-child(3) {
                background: $white;
                color: $dark-grey;
                font-weight: 600;
                text-decoration: none;

                &:hover {
                    background: $light-bg;
                    color: $main-blue;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
        }
    }
}
