@import 'src/assets/styles/utilities/variables';

.ouic-modal.light-theme {
  div.ouic-table {
    .trow:hover {
      background-color: lightcyan;
    }
  }

  form.ouic-drag-and-drop label {
    color: #1F72F2;
  }

  form.ouic-drag-and-drop {
    background-color: $white;
    border-color: $grey-bd;
    color: $black;

    svg g {
      fill: $black;
    }
  }

  .modal-content {
    background: $white;
    color: $dark-grey;
  }

  .ouic-button.secondary:hover {
    background: $white;
  }

  .ouic-checkbox {
    label {
      color: $dark-grey;
    }
  }

  .ouic-dropdown__menu-list {
    background-color: $white;
    border: 1px solid #1f72f2;
    color: $dark-grey !important;

    > * > *,
    > * {
      color: $dark-grey;

      .selected,
      .active {
        color: $white;
      }
    }
  }

  .ouic-dropdown .ouic-dropdown__single-value {
    color: $dark-grey;
  }

  .ouic-form-input input,
  .ouic-form-input textarea {
    background: $light-bg;
    color: $dark-grey;

    &:focus {
      background: $light-bg;
    }
  }

  .ouic-dropdown {
    .ouic-dropdown__control {
      &.ouic-dropdown__control--menu-is-open {
        border-color: $main-blue;
        background: $white;
      }
    }
    .ouic-dropdown__option--is-selected,
    .ouic-dropdown__option--is-selected:hover {
      background: $grey-9b;
    }
  }
}

div.custom-popover.light-theme {
  background-color: $light-bg;
  border: none;

  .popover__button.link.ouic-button {
    color: $dark-grey;
  }

  .popover__arrow {
    border-left-color: $light-bg;
  }

  .popover__item-list {
    .ouic-checkbox label {
      color: $dark-grey;
    }
  }

  .ouic-dropdown__menu-list {
    background-color: $white;
    border: 1px solid #1f72f2;
    color: $dark-grey !important;

    > * > *,
    > * {
      color: $dark-grey;

      .selected,
      .active {
        color: $white;
      }
    }
  }

  .ouic-form-input input,
  .ouic-form-input textarea {
    background: $light-bg;
    color: $dark-grey;

    &:focus {
      background: $light-bg;
    }
  }

  .ouic-dropdown .ouic-dropdown__single-value {
    color: $dark-grey;
  }

  .ouic-dropdown {
    .ouic-dropdown__control {
      &.ouic-dropdown__control--menu-is-open {
        border-color: $main-blue;
        background: $white;
      }
    }
    .ouic-dropdown__option--is-selected,
    .ouic-dropdown__option--is-selected:hover {
      background: $grey-9b;
    }
  }
}
