@import '../utilities/variables';

.table--border {
    border: 1px solid lightgray;
}

.text--uppercase {
    font-weight: bolder;
}

.ouic-table {
    .thead {
        background: $blue-grey;
        color: $dark-grey;
    }

    .trow {
        background: $white;
        border-bottom: 1px solid $grey-e;
        color: $dark-grey;

        &:hover {
            background: $light-bg;
        }

    }

    .expanded_row__container {
        .trow.expand {
            background: $light-bg;

            .trow {
                background: $light-bg;
            }
        }
    }

}

.ouic-dropdown {
    .ouic-dropdown__single-value {
        color: $dark-grey;
        width: calc(100% - 15px);
    }

    &__menu {
        background: $white !important;
        color: $dark-grey !important;
    }

    &--label {
        background: $white;
        margin-bottom: 0;
        padding-bottom: 0.5em;
    }
}

.ouic-dropdown .ouic-dropdown__control.ouic-dropdown__control--menu-is-open {
    background: $white;
    color: $dark-grey !important;
}

.ouic-pagination {
    li {
        a {
            color: $dark-grey;
        }

        &.active a {
            background: $white;
            color: $main-blue;
        }

        &:hover {
            a {
                background: $grey;
            }
        }
    }
}

.kebab .circle {
    background: $dark-grey;
}

button.ouic-button.active {
    background: $white;
}

.ouic-button.secondary:hover {
    color: $main-blue;
    background: $white;
}

.custom-popover.custom_popover--right {
    background: $white;
    border-color: $white;
}

.popover__arrow {
    border-left: 20px solid $white;
}

.ouic-dropdown__menu-list {
    background-color: $white;
    border: 1px solid #1F72F2;
    color: $dark-grey !important;

    > * > *,
    > * {
        color: $dark-grey;

        .selected,
            .active{
            color: $white;
        }
    }
}
