@import 'src/assets/styles/utilities/variables';

#responses {
  .responses__header-row {
    background: #e4edf2;
    border-color: $grey-e;
  }

  .response-panel {
    border-color: $grey-e;
  }
}

.filters-container {
  .filters {
    background: $white;
    border-color: $grey-e;
    box-shadow: $box-shadow;
  }
}

.select-options {
  border-color: $grey-e;
}
