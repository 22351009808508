@import "../../assets/styles/utilities/variables";

footer {
  color: #edf0f9;
  font-size: 13px;
  height: 145px;
  padding: 20px 0;
  position: relative;
  z-index: 10;

  .ouic-card {
    width: 100%;

    .ouic-card-footer {
      align-items: center;
      display: flex;

      > div {
        width: 100%;
        padding: 0 15px;
      }
    }
  }

  a {
    color: #D9DCE3;

    &:hover {
      color: #f2f4f6;
    }
  }

  .copyright {
    margin: 10px 0;
    text-align: center;
  }

  .logo {
    width: 45px;
    height: 45px;
    display: block;
    margin: 10px auto 0;
  }

  .contact-support {
    text-align: center;
  }

  &.login-footer {
    background: $bg-footer;
    text-align: center;
    height: 110px;
    color: #a3a6a9;

    .ouic-card-footer {
      display: block;
    }

    .logo {
      height: 20px;
      display: block;
      margin: 0 auto;
    }

    .copyright {
      margin: 5px 0 10px;
      display: inline-block;
    }
  }
}

@media (min-width: 768px) {
  footer {
    height: 110px;

    .copyright {
      margin: 30px 0;
      text-align: left;
    }

    .contact-support {
      text-align: right;
    }

    .logo {
      margin-left: 20px;
      margin-top: 0;
      display: inline-block;
    }
  }
}
