@import "../../assets/styles/utilities/variables";

.message-dropdown {
  border-color: rgba(100, 133, 207, 0.2);
  list-style: none;
  width: 300px;

  &--no-padding {
    padding-left: 0;
  }

  &__list {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }

  &__item {
    color: $text-blue;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 25px;
    text-align: center;

    a {
      color: $text-blue;
      text-decoration: none;

      &:hover {
        color: $text-blue;
      }
    }
  }

  &:before {
    border-bottom-color: $text-blue;
  }

  .ouic-card-header {
    background: $text-blue;
    color: $white;
    cursor: default;
    font-size: 12px;
    font-weight: 500;
    margin: 0 -1px;
    position: relative;
    padding: 15px 20px;
    text-transform: uppercase;

    .badge {
      position: absolute;
      top: 50%;
      height: 18px;
      min-width: 18px;
      padding: 3px;
      margin-left: 8px;
      font-family: $montserrat;
      font-size: 11px;
      font-weight: 500;
      background: #2c3950;
      transform: translateY(-50%);
    }
  }

  .message-preview {
    border-bottom: 1px solid $grey-9b;
    cursor: pointer;
    padding: 15px 20px;
  }

  .message-heading {
    .sender {
      text-transform: none;
    }

    .timestamp {
      color: #ccc;
      float: right;
      font-size: 12px;
      font-weight: 600;
      text-transform: none;

      .has-attachment {
        color: #2c3950;
        margin-right: 8px;
      }
    }

    .subject {
      color: $text-blue;
      text-transform: none;
    }

    .message-footer {
      background: #f7f7f7;
      color: #2c3950;
      font-size: 12px;
      padding: 10px 25px;
      text-align: center;
    }
  }

  .message-content {
    max-width: 275px;
    overflow: hidden;
    color: $text-grey;
    font-family: $libre;
    font-weight: 400;
    text-overflow: ellipsis;
    text-transform: none;
  }
}

.user-menu {
  .ouic-card {
    width: 100%;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li > a,
      li:nth-child(3) {
        background: $black;
        clear: both;
        cursor: pointer;
        color: $white;
        display: block;
        font-size: 13px;
        font-family: $montserrat;
        line-height: 1.5;
        padding: 10px 15px;
        text-decoration: none;
        white-space: nowrap;

        &:hover {
          background: $dark-bg;
        }
      }
    }
  }
}

.dropdown-menu {
  background-clip: padding-box;
  border-radius: 3px;
  border-top: 0;
  display: none;
  font-size: 12px;
  float: left;
  list-style: none;
  min-width: 130px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  top: 100%;
  text-align: left;
  z-index: 1000;

  .ouic-card-body {
    padding: 0;
  }

  &:before {
    border-right: 7px solid transparent;
    border-bottom: 7px solid $text-blue;
    border-left: 7px solid transparent;
    content: "";
    height: 0;
    position: absolute;
    right: 15px;
    top: -7px;
    width: 0;
  }

  > li.active > a,
  > li.active > a:hover {
    background: transparent;
    color: $text-blue;
  }

  > li > a {
    clear: both;
    color: $text-grey;
    display: block;
    font-size: 13px;
    font-family: $montserrat;
    font-weight: 600;
    line-height: 1.5;
    padding: 10px 15px;
    white-space: nowrap;

    &:hover {
      background: #f1f6f9;
    }
  }

  > li > a > img {
    margin-right: 10px;
  }

  &.pull-right {
    right: 0;
    left: auto;
  }

  .divider {
    height: 1px;
    margin: 8px 0;
    overflow: hidden;
    background-color: #e5e5e5;
    margin: 0 10px;
  }

  > li > a:hover,
  > li > a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #e4ecef;
  }
}


.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: $white;
  text-decoration: none;
  outline: 0;
  background-color: #2c3950; }

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #D9DCE3; }

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed; }

.open > .dropdown-menu {
  display: block; }

.open > a {
  outline: 0; }

.dropdown-menu-right {
  left: auto;
  right: 0; }

.dropdown-menu-left {
  left: 0;
  right: auto; }

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 11px;
  line-height: 1.5;
  color: $grey-9b;
  white-space: nowrap; }

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990; }

.pull-right > .dropdown-menu {
  right: 0;
  left: auto; }

.messages > .dropdown-menu {
  > li > a {
    padding: 0;
  }
}

.navbar-nav .open .dropdown-menu.message-dropdown {
  background: $white;
}

.filter {
  display: inline-block;
  margin-right: 30px;
  font-family: $montserrat;
  font-weight: 500;

  .btn-group .btn.dropdown-toggle {
    font-size: 14px;
  }
}

.filter-btn .unfiltered {
  color: #2c3950; }

.filter-label {
  margin-right: 10px;
  margin-bottom: 0;
  color: $grey-9b;
  font-size: 12px;
  text-transform: uppercase;
  vertical-align: middle;
}

.filter-popover {
  font-size: 13px;

  .popover-content {
    width: 250px;
    padding: 14px;

    .form-control {
      height: 30px;
      margin-bottom: 10px;
      font-size: 12px;
    }
  }

  .select-options {
    height: auto;
    max-height: 198px;
    padding: 10px 15px 0;
  }
}
