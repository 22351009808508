@import '../utilities/variables';

.panel--border {
    border: none;
}

.ouic-panel {
    background: $white;
}

.ouic-panel-heading {
    background: $white;
    color: $bg-header;
}

div.asset-table.react-bs-table-container .table > tbody > tr > td,
div.react-bs-table-container .table thead {
    border-bottom: 2px solid $grey-e;
}
