@import "../../assets/styles/utilities/variables";

.message-preview {
  &:hover {
    opacity: .8;
  }

  &__content {
    font-weight: 600;
    width: 96%;
  }

  &__sender {
    color: $text-grey;
    display: inline-block;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 60%;
  }

  &__subject {
    font-weight: 600;
    width: 80%;
  }

  &__item--truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.empty-messages {
  font-size: 20px;
  margin: 0;
  padding: 20px;
  text-align: center;
}

.message-list_network {
  color: $white;
  display: inline-block;
  font-weight: 400;
}
