.env-banner {
  border-bottom-left-radius: 9px;
  border-bottom-right-radius: 9px;
  font-size: 14px;
  position: absolute;
  right: 30%;
  text-align: center;
  top: 0;
  width: 40%;
  z-index: 9999;
}
