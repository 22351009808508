@import "./assets/styles/utilities/variables";

html {
  box-sizing: border-box;
}

#app,
body {
  margin: 0;
  padding: 0;
  color: $white;

  &::-webkit-scrollbar-thumb {
    background-color: $grey;
    outline: 1px solid transparent;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
}

.content {
  background-color: $dark-bg;
  min-height: calc(100vh - 160px);
}

.fade {
  opacity: 1;
}

.panel--border {
  border: 1px solid $dark-grey-border;
}

nav.ouic-breadcrumb {
  font-size: 14px;
  margin-top: 10px;
}


// This are styles for responses-page copy-paste
.copy-table-container {
  .label-r {
    font-weight: bold;
    display: inline-block;
  }

  .date {
    font-size: 12px;
    color: #657089;
    border: 2px solid black;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    width: 500px;
  }

  .background {
    background-color: #F1F6F9;

    > div:first-child {
      font-size: 20px;
    }
  }

  .responses {
    margin-left: 10px;
    background-color: #F5F5F5;
  }
}

.copy-response-table {
  width: 600px;
  font-weight: normal;
  td {
    border: 1px solid black;
  }

  .depts {

    > :first-child {
      width: 100px;
    }
  }
}

.table-h-r {
  display: inline-block;
}

div.fade#tooltip {
  opacity: 1;
}

.ms {
  margin: 6px 0;
}
