@import '../utilities/variables';

#messages {
    background: $white;
    border: none;

    .messages-controls {
        border-bottom: 1px solid $grey-e;

        .ouic-form-input {
            input {
                background: $light-bg;
                border: 1px solid $grey-e;
                color: $dark-grey;
            }
        }
    }

    .messages-container {
        background: $white;

        .ouic-card {
            border-right: 1px solid $grey-e;
        }
    }
}

.search-results-header {
    background: $white;
    border: none;
    box-shadow: $box-shadow
}

.form-control {
    background: $light-bg;
    color: black;
}

.ouic-label-badge.secondary {
    background: transparent;
}

.message-input-dropdown {
    background: $white;
    box-shadow: $box-shadow;
    color: $dark-grey;
    border: none;

    > div {
        &:hover {
            background: $light-bg;

            span {
                color: $dark-grey;
            }
        }
    }
}

.messages-list {
    .messageList-message {
        background: $blue-grey;
        border: 1px solid $grey-e;
        border-right-color: transparent;
        border-left-color: $main-blue;
        opacity: 1;

        &.active {
            background: $white;
        }

        &_container {
            background: $white;
        }

        h2 {
            color: $dark-grey;
        }
    }

    .messageList-item {
        &_messagePreview {
            color: $dark-grey;
        }

        &_container {
            background: transparent
        }
    }
}

.ouic-card-body,
.ouic-card {
    background: $white;
}

.dock-modal {
    background: $white;
    border: none;

    .ouic-panel-heading {
        background: $light-blue;
        color: $white;
    }
}

.ouic-panel-body  {
    input {
        background: $light-bg;
    }
}

.ouic-form-input {
    textarea,
    input {
        background: $light-bg;
        border-color: $grey-e;
        color: $dark-grey;

        &:focus {
            border-color: $grey-e;
            color: $dark-grey;
        }
    }
}

.ouic-drag-and-drop {
    background: $white;

    label {
        color: $main-blue;
    }
}

.ouic-drop-area {
    background: transparent;
}

.ouic-dropdown {
    &__menu.css-26l3qy-menu,
    &--select {
        background: $light-bg !important;
        border-color: $grey-e !important;
        color: $dark-grey !important;
    }

    .ouic-dropdown__control {
        border-color: $grey-e;
    }
}

::-webkit-resizer {
    display: none;
}
