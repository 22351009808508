@import 'src/assets/styles/utilities/variables';

.light-theme {
  .rs-date-input {
    background-color: #f2f4f6;
    > a {
      > span {
        color: #333 !important;
      }
    }
  }

  .rs-picker-menu {
    background-color: red;
  }

  .ouic-dropdown .ouic-dropdown__input {
    color: $black;
  }

  .flatpickr-input {
    color: $black;
    &:focus {
      background: $light-bg;
    }
  }
}

#app.light-theme {
  background: $light-bg;
  color: $black;

  .ouic-dropdown.ouic-dropdown--is-multi .ouic-dropdown__option--is-focused:hover {
    background-color: $light-bg;
  }

  .ouic-dropdown {
    .ouic-dropdown__control {
      &.ouic-dropdown__control--menu-is-open {
        border-color: $main-blue;
        background: $white;
      }
    }
    .ouic-dropdown__option--is-selected,
    .ouic-dropdown__option--is-selected:hover {
      background: $grey-9b;
    }

    .ouic-dropdown__input {
      color: $black;
    }
  }

  .recipient {
    div.message-list_network {
      color: $black;
    }
  }

  .document-uploader-container {
    svg {
      > g > g > g {
        fill: $black;
      }
    }
  }

  .reviewer-overrides {
    margin-top: 10px;
    border-color: lightgray;
    background-color: $white;
  }

  .content {
    background: $light-bg;
  }

  button.button__link--grey.link {
    color: $black;
    font-weight: normal;
  }

  .ouic-page-title h1 {
    color: $dark-grey;
  }

  .text-white {
    color: $dark-grey !important;
  }

  .ouic-card-header {
    border-bottom: 1px solid $grey-e;
  }

  .ouic-checkbox label {
    color: $grey-9b;
  }

  .ouic-loading-spinner.fullpage {
    background: rgba($white, 0.8);
  }

  .flatpickr-input {
    color: $dark-grey;
  }

  .response-body .status {
    background: $white;
    border-color: $grey-9b;
  }

  //Header
  @import './assets/styles/light-theme/light-header';

  //Footer
  @import './assets/styles/light-theme/light-footer';

  //Dashboard
  @import './assets/styles/light-theme/light-dashboard';

  //Messages
  @import './assets/styles/light-theme/light-messages';

  //Packages
  @import './assets/styles/light-theme/light-packages';

  //User Settings
  @import './assets/styles/light-theme/light-user-settings';

  //Responses
  @import './assets/styles/light-theme/light-responses';
}

//Modals
@import './assets/styles/light-theme/light-modals';
