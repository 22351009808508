@charset "UTF-8";

@import 'src/assets/styles/utilities/variables';

html {
  font-family: $sans;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

/*Part of Chrome Autofill detection hack*/
/*input#username:-webkit-autofill {
    transition: background-color 0.1s ease-in-out 0s;
}*/

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: $black;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: $monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: $black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: ' (' attr(href) ')';
  }
  abbr[title]:after {
    content: ' (' attr(title) ')';
  }
  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .navbar {
    display: none;
  }
  .btn > .caret,
  .dropup > .btn > .caret {
    border-top-color: $black !important;
  }
  .label {
    border: 1px solid $black;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: $white !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}

#app {
  background-color: $dark-bg;
  color: $white;
  font-family: $libre;
  font-size: 12px;
  line-height: 1.5;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: $text-blue;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #2c3950;
  text-decoration: underline;
}
a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 20px;
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.5;
  background-color: #f2f4f6;
  border: 1px solid #ddd;
  border-radius: 3px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  border-top: 1px solid #f7f7f7;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

[role='button'] {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $montserrat;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h1 small,
h1 .small,
h2 small,
h2 .small,
h3 small,
h3 .small,
h4 small,
h4 .small,
h5 small,
h5 .small,
h6 small,
h6 .small,
.h1 small,
.h1 .small,
.h2 small,
.h2 .small,
.h3 small,
.h3 .small,
.h4 small,
.h4 .small,
.h5 small,
.h5 .small,
.h6 small,
.h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #d9dce3;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: 18px;
  margin-bottom: 9px;
}
h1 small,
h1 .small,
.h1 small,
.h1 .small,
h2 small,
h2 .small,
.h2 small,
.h2 .small,
h3 small,
h3 .small,
.h3 small,
.h3 .small {
  font-size: 65%;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 9px;
  margin-bottom: 9px;
}
h4 small,
h4 .small,
.h4 small,
.h4 .small,
h5 small,
h5 .small,
.h5 small,
.h5 .small,
h6 small,
h6 .small,
.h6 small,
.h6 .small {
  font-size: 75%;
}

h1,
.h1 {
  font-size: 48px;
}

h2,
.h2 {
  font-size: 36px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 18px;
}

h5,
.h5 {
  font-size: 16px;
}

h6,
.h6 {
  font-size: 14px;
}

p {
  margin: 0 0 9px;
}

.lead {
  margin-bottom: 18px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1.4;
}
@media (min-width: 768px) {
  .lead {
    font-size: 18px;
  }
}

small,
.small {
  font-size: 91%;
}

mark,
.mark {
  background-color: #fcf8e3;
  padding: 0.2em;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase,
.initialism {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #d9dce3;
}

.text-muted:hover {
  color: #a4a4ab;
  cursor: not-allowed;
  text-decoration: none;
}

.text-primary {
  color: #2c3950;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #1a222f;
}

.text-success {
  color: #49b218;
}

a.text-success:hover,
a.text-success:focus {
  color: #378512;
}

.text-info {
  color: #31708f;
}

a.text-info:hover,
a.text-info:focus {
  color: #245269;
}

.text-warning {
  color: #ec9d00;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #b97b00;
}

.text-danger {
  color: #e60012;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #b3000e;
}

.bg-primary {
  color: $white;
}

.bg-primary {
  background-color: #2c3950;
}

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #1a222f;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:hover,
a.bg-success:focus {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:hover,
a.bg-info:focus {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #e4b9b9;
}

.page-header {
  padding-bottom: 8px;
  margin: 36px 0 18px;
  border-bottom: 1px solid #f7f7f7;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 9px;
}
ul ul,
ul ol,
ol ul,
ol ol {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}
.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

dl {
  margin-top: 0;
  margin-bottom: 18px;
}

dt,
dd {
  line-height: 1.5;
}

dt {
  font-weight: bold;
}

dd {
  margin-left: 0;
}

.dl-horizontal dd:before,
.dl-horizontal dd:after {
  content: ' ';
  display: table;
}

.dl-horizontal dd:after {
  clear: both;
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #d9dce3;
}

.initialism {
  font-size: 90%;
}

blockquote {
  padding: 9px 18px;
  margin: 0 0 18px;
  font-size: 15px;
  border-left: 5px solid #f7f7f7;
}
blockquote p:last-child,
blockquote ul:last-child,
blockquote ol:last-child {
  margin-bottom: 0;
}
blockquote footer,
blockquote small,
blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.5;
  color: #d9dce3;
}
blockquote footer:before,
blockquote small:before,
blockquote .small:before {
  content: '\2014 \00A0';
}

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #f7f7f7;
  border-left: 0;
  text-align: right;
}
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
.blockquote-reverse .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before,
blockquote.pull-right .small:before {
  content: '';
}
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
.blockquote-reverse .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after,
blockquote.pull-right .small:after {
  content: '\00A0 \2014';
}

address {
  margin-bottom: 18px;
  font-style: normal;
  line-height: 1.5;
}

code,
kbd,
pre,
samp {
  font-family: $menlo;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 3px;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: $white;
  background-color: $dark-grey;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  box-shadow: none;
}

pre {
  display: block;
  padding: 8.5px;
  margin: 0 0 9px;
  font-size: 11px;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
  color: $grey-9b;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 3px;
}
pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding: 0 15px;
}
/*
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }
  /* @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1200px) {
    .container {
      width: 1170px; } }
  @media (min-width: 1400px) {
    .container {
      width: 1430px; } } */

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.container-fluid:before,
.container-fluid:after {
  content: ' ';
  display: table;
}
.container-fluid:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}
.row:before,
.row:after {
  content: ' ';
  display: table;
}
.row:after {
  clear: both;
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  width: 66.66667%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.33333%;
  }
  .col-sm-2 {
    width: 16.66667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33333%;
  }
  .col-sm-5 {
    width: 41.66667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33333%;
  }
  .col-sm-8 {
    width: 66.66667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33333%;
  }
  .col-sm-11 {
    width: 91.66667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 8.33333%;
  }
  .col-sm-pull-2 {
    right: 16.66667%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.33333%;
  }
  .col-sm-pull-5 {
    right: 41.66667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.33333%;
  }
  .col-sm-pull-8 {
    right: 66.66667%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.33333%;
  }
  .col-sm-pull-11 {
    right: 91.66667%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 8.33333%;
  }
  .col-sm-push-2 {
    left: 16.66667%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.33333%;
  }
  .col-sm-push-5 {
    left: 41.66667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.33333%;
  }
  .col-sm-push-8 {
    left: 66.66667%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.33333%;
  }
  .col-sm-push-11 {
    left: 91.66667%;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-1 {
    width: 8.33333%;
  }
  .col-md-2 {
    width: 16.66667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33333%;
  }
  .col-md-5 {
    width: 41.66667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33333%;
  }
  .col-md-8 {
    width: 66.66667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33333%;
  }
  .col-md-11 {
    width: 91.66667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 8.33333%;
  }
  .col-md-pull-2 {
    right: 16.66667%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-4 {
    right: 33.33333%;
  }
  .col-md-pull-5 {
    right: 41.66667%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 58.33333%;
  }
  .col-md-pull-8 {
    right: 66.66667%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-10 {
    right: 83.33333%;
  }
  .col-md-pull-11 {
    right: 91.66667%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 8.33333%;
  }
  .col-md-push-2 {
    left: 16.66667%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-4 {
    left: 33.33333%;
  }
  .col-md-push-5 {
    left: 41.66667%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 58.33333%;
  }
  .col-md-push-8 {
    left: 66.66667%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-10 {
    left: 83.33333%;
  }
  .col-md-push-11 {
    left: 91.66667%;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333%;
  }
  .col-md-offset-2 {
    margin-left: 16.66667%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333%;
  }
  .col-md-offset-5 {
    margin-left: 41.66667%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333%;
  }
  .col-md-offset-8 {
    margin-left: 66.66667%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333%;
  }
  .col-md-offset-11 {
    margin-left: 91.66667%;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.33333%;
  }
  .col-lg-2 {
    width: 16.66667%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33333%;
  }
  .col-lg-5 {
    width: 41.66667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33333%;
  }
  .col-lg-8 {
    width: 66.66667%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33333%;
  }
  .col-lg-11 {
    width: 91.66667%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 8.33333%;
  }
  .col-lg-pull-2 {
    right: 16.66667%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.33333%;
  }
  .col-lg-pull-5 {
    right: 41.66667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.33333%;
  }
  .col-lg-pull-8 {
    right: 66.66667%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.33333%;
  }
  .col-lg-pull-11 {
    right: 91.66667%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 8.33333%;
  }
  .col-lg-push-2 {
    left: 16.66667%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.33333%;
  }
  .col-lg-push-5 {
    left: 41.66667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.33333%;
  }
  .col-lg-push-8 {
    left: 66.66667%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.33333%;
  }
  .col-lg-push-11 {
    left: 91.66667%;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
}

@media (min-width: 1400px) {
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    float: left;
  }
  .col-xl-1 {
    width: 8.33333%;
  }
  .col-xl-2 {
    width: 16.66667%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.33333%;
  }
  .col-xl-5 {
    width: 41.66667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.33333%;
  }
  .col-xl-8 {
    width: 66.66667%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.33333%;
  }
  .col-xl-11 {
    width: 91.66667%;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-pull-0 {
    right: auto;
  }
  .col-xl-pull-1 {
    right: 8.33333%;
  }
  .col-xl-pull-2 {
    right: 16.66667%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-4 {
    right: 33.33333%;
  }
  .col-xl-pull-5 {
    right: 41.66667%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-7 {
    right: 58.33333%;
  }
  .col-xl-pull-8 {
    right: 66.66667%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-10 {
    right: 83.33333%;
  }
  .col-xl-pull-11 {
    right: 91.66667%;
  }
  .col-xl-pull-12 {
    right: 100%;
  }
  .col-xl-push-0 {
    left: auto;
  }
  .col-xl-push-1 {
    left: 8.33333%;
  }
  .col-xl-push-2 {
    left: 16.66667%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-4 {
    left: 33.33333%;
  }
  .col-xl-push-5 {
    left: 41.66667%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-7 {
    left: 58.33333%;
  }
  .col-xl-push-8 {
    left: 66.66667%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-10 {
    left: 83.33333%;
  }
  .col-xl-push-11 {
    left: 91.66667%;
  }
  .col-xl-push-12 {
    left: 100%;
  }
  .col-xl-offset-0 {
    margin-left: 0%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66667%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66667%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66667%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66667%;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
}

table {
  background-color: transparent;
}

caption {
  padding-top: 18px 10px;
  padding-bottom: 18px 10px;
  color: #d9dce3;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 18px;
}
.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
  padding: 9px 10px;
  line-height: 1.5;
  vertical-align: top;
  border-top: 1px solid #d9dce3;
}
.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #d9dce3;
}
.table > caption + thead > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > th,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 2px solid #d9dce3;
}

.table-condensed > thead > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > th,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > th,
.table-condensed > tfoot > tr > td {
  padding: 8px;
}

.table-bordered {
  border: 1px solid #d9dce3;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > th,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > th,
.table-bordered > tfoot > tr > td {
  border: 1px solid #d9dce3;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #fafafa;
}

table col[class*='col-'] {
  position: static;
  float: none;
  display: table-column;
}

table td[class*='col-'],
table th[class*='col-'] {
  position: static;
  float: none;
  display: table-cell;
}

.table > thead > tr > td.active,
.table > thead > tr > th.active,
.table > thead > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background-color: #fafafa;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: #ededed;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success,
.table > thead > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background-color: #dff0d8;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info,
.table > thead > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background-color: #d9edf7;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr:hover > .info,
.table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning,
.table > thead > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background-color: #fcf8e3;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr:hover > .warning,
.table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger,
.table > thead > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr:hover > .danger,
.table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc;
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%;
}
@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 13.5px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #d9dce3;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 18px;
  font-size: 18px;
  line-height: inherit;
  color: $grey-9b;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type='search'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type='radio'],
input[type='checkbox'] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type='file'] {
  display: block;
}

input[type='range'] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type='file']:focus,
input[type='radio']:focus,
input[type='checkbox']:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 12px;
  line-height: 1.5;
  color: #141419;
}

.filter-form-group {
  display: block;
  width: 275px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.filter-form-group-reviewer {
  display: block;
  width: 275px;
  margin-left: 30px;
  margin-bottom: 15px;
}
.filter-form-second-noParamount-group {
  display: block;
  width: 275px;
  margin-left: 10px;
}

.filter-form-second-paramount-group {
  display: block;
  width: 225px;
  margin-left: 10px;
}
.form-control {
  display: block;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.5;
  color: #141419;
  background-color: #f3f6f6;
  background-image: none;
  border: 1px solid #dee5e7;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control:focus {
  border-color: transparent;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 0, 0, 0.6);
}
.form-control::-moz-placeholder {
  color: $grey-9b;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: $grey-9b;
}
.form-control::-webkit-input-placeholder {
  color: $grey-9b;
}
.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #f3f6f6;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type='search'] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='date'].form-control,
  input[type='time'].form-control,
  input[type='datetime-local'].form-control,
  input[type='month'].form-control {
    line-height: 40px;
  }
  input[type='date'].input-sm,
  .input-group-sm > input[type='date'].form-control,
  .input-group-sm > input[type='date'].input-group-addon,
  .input-group-sm > .input-group-btn > input[type='date'].btn,
  .input-group-sm input[type='date'],
  input[type='time'].input-sm,
  .input-group-sm > input[type='time'].form-control,
  .input-group-sm > input[type='time'].input-group-addon,
  .input-group-sm > .input-group-btn > input[type='time'].btn,
  .input-group-sm input[type='time'],
  input[type='datetime-local'].input-sm,
  .input-group-sm > input[type='datetime-local'].form-control,
  .input-group-sm > input[type='datetime-local'].input-group-addon,
  .input-group-sm > .input-group-btn > input[type='datetime-local'].btn,
  .input-group-sm input[type='datetime-local'],
  input[type='month'].input-sm,
  .input-group-sm > input[type='month'].form-control,
  .input-group-sm > input[type='month'].input-group-addon,
  .input-group-sm > .input-group-btn > input[type='month'].btn,
  .input-group-sm input[type='month'] {
    line-height: 28px;
  }
  input[type='date'].input-lg,
  .input-group-lg > input[type='date'].form-control,
  .input-group-lg > input[type='date'].input-group-addon,
  .input-group-lg > .input-group-btn > input[type='date'].btn,
  .input-group-lg input[type='date'],
  input[type='time'].input-lg,
  .input-group-lg > input[type='time'].form-control,
  .input-group-lg > input[type='time'].input-group-addon,
  .input-group-lg > .input-group-btn > input[type='time'].btn,
  .input-group-lg input[type='time'],
  input[type='datetime-local'].input-lg,
  .input-group-lg > input[type='datetime-local'].form-control,
  .input-group-lg > input[type='datetime-local'].input-group-addon,
  .input-group-lg > .input-group-btn > input[type='datetime-local'].btn,
  .input-group-lg input[type='datetime-local'],
  input[type='month'].input-lg,
  .input-group-lg > input[type='month'].form-control,
  .input-group-lg > input[type='month'].input-group-addon,
  .input-group-lg > .input-group-btn > input[type='month'].btn,
  .input-group-lg input[type='month'] {
    line-height: 42px;
  }
}

.form-group {
  margin-bottom: 15px;
}

.radio,
.checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
.radio label,
.checkbox label {
  min-height: 18px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.radio input[type='radio'],
.radio-inline input[type='radio'],
.checkbox input[type='checkbox'],
.checkbox-inline input[type='checkbox'] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
  margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input[type='radio'][disabled],
input[type='radio'].disabled,
fieldset[disabled] input[type='radio'],
input[type='checkbox'][disabled],
input[type='checkbox'].disabled,
fieldset[disabled] input[type='checkbox'] {
  cursor: not-allowed;
}

.radio-inline.disabled,
fieldset[disabled] .radio-inline,
.checkbox-inline.disabled,
fieldset[disabled] .checkbox-inline {
  cursor: not-allowed;
}

.radio.disabled label,
fieldset[disabled] .radio label,
.checkbox.disabled label,
fieldset[disabled] .checkbox label {
  cursor: not-allowed;
}

.form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  min-height: 30px;
}
.form-control-static.input-lg,
.input-group-lg > .form-control-static.form-control,
.input-group-lg > .form-control-static.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-static.btn,
.form-control-static.input-sm,
.input-group-sm > .form-control-static.form-control,
.input-group-sm > .form-control-static.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-left: 0;
  padding-right: 0;
}

.input-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  height: 28px;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

select.input-sm,
.input-group-sm > select.form-control,
.input-group-sm > select.input-group-addon,
.input-group-sm > .input-group-btn > select.btn {
  height: 28px;
  line-height: 28px;
}

textarea.input-sm,
.input-group-sm > textarea.form-control,
.input-group-sm > textarea.input-group-addon,
.input-group-sm > .input-group-btn > textarea.btn,
select[multiple].input-sm,
.input-group-sm > select[multiple].form-control,
.input-group-sm > select[multiple].input-group-addon,
.input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto;
}

.form-group-sm .form-control {
  height: 28px;
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 2px;
}

.form-group-sm select.form-control {
  height: 28px;
  line-height: 28px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 28px;
  min-height: 29px;
  padding: 6px 10px;
  font-size: 11px;
  line-height: 1.5;
}

.input-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  height: 42px;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.33333;
  border-radius: 4px;
}

select.input-lg,
.input-group-lg > select.form-control,
.input-group-lg > select.input-group-addon,
.input-group-lg > .input-group-btn > select.btn {
  height: 42px;
  line-height: 42px;
}

textarea.input-lg,
.input-group-lg > textarea.form-control,
.input-group-lg > textarea.input-group-addon,
.input-group-lg > .input-group-btn > textarea.btn,
select[multiple].input-lg,
.input-group-lg > select[multiple].form-control,
.input-group-lg > select[multiple].input-group-addon,
.input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto;
}

.form-group-lg .form-control {
  height: 42px;
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.33333;
  border-radius: 4px;
}

.form-group-lg select.form-control {
  height: 42px;
  line-height: 42px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 42px;
  min-height: 33px;
  padding: 11px 16px;
  font-size: 15px;
  line-height: 1.33333;
}

.has-feedback {
  position: relative;
}
.has-feedback .form-control {
  padding-right: 50px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  pointer-events: none;
}

.input-lg + .form-control-feedback,
.input-group-lg > .form-control + .form-control-feedback,
.input-group-lg > .input-group-addon + .form-control-feedback,
.input-group-lg > .input-group-btn > .btn + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
  width: 42px;
  height: 42px;
  line-height: 42px;
}

.input-sm + .form-control-feedback,
.input-group-sm > .form-control + .form-control-feedback,
.input-group-sm > .input-group-addon + .form-control-feedback,
.input-group-sm > .input-group-btn > .btn + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline,
.has-success.radio label,
.has-success.checkbox label,
.has-success.radio-inline label,
.has-success.checkbox-inline label {
  color: #49b218;
}

.has-success .form-control {
  border-color: #49b218;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-success .form-control:focus {
  border-color: #378512;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7be749;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7be749;
}

.has-success .input-group-addon {
  color: #49b218;
  border-color: #49b218;
  background-color: #dff0d8;
}

.has-success .form-control-feedback {
  color: #49b218;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline,
.has-warning.radio label,
.has-warning.checkbox label,
.has-warning.radio-inline label,
.has-warning.checkbox-inline label {
  color: #ec9d00;
}

.has-warning .form-control {
  border-color: #ec9d00;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-warning .form-control:focus {
  border-color: #b97b00;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffc553;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ffc553;
}

.has-warning .input-group-addon {
  color: #ec9d00;
  border-color: #ec9d00;
  background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
  color: #ec9d00;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #e60012;
}

.has-error .form-control {
  border-color: #e60012;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.has-error .form-control:focus {
  border-color: #b3000e;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ff4d5b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ff4d5b;
}

.has-error .input-group-addon {
  color: #e60012;
  border-color: #e60012;
  background-color: #f2dede;
}

.has-error .form-control-feedback {
  color: #e60012;
}

.has-feedback label ~ .form-control-feedback {
  top: 23px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0;
  }
  .form-inline .radio input[type='radio'],
  .form-inline .checkbox input[type='checkbox'] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 7px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 25px;
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px;
}
.form-horizontal .form-group:before,
.form-horizontal .form-group:after {
  content: ' ';
  display: table;
}
.form-horizontal .form-group:after {
  clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 11px;
  }
}

.btnx {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: $dark-grey;
  text-decoration: none;
}
.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
fieldset[disabled] .btn {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 2px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: $dark-grey;
  text-decoration: none;
}
.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: $dark-grey;
  background-color: $white;
  border-color: #ccc;
}
.btn-default:focus,
.btn-default.focus {
  color: $dark-grey;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: $dark-grey;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.open > .btn-default.dropdown-toggle {
  color: $dark-grey;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active:hover,
.btn-default:active:focus,
.btn-default:active.focus,
.btn-default.active:hover,
.btn-default.active:focus,
.btn-default.active.focus,
.open > .btn-default.dropdown-toggle:hover,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle.focus {
  color: $dark-grey;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default:active,
.btn-default.active,
.open > .btn-default.dropdown-toggle {
  background-image: none;
}
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled.focus,
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus {
  background-color: $white;
  border-color: #ccc;
}
.btn-default .badge {
  color: $white;
  background-color: $dark-grey;
}

.btn-primary {
  color: $white;
  background-color: $text-blue;
  border-color: $text-blue;
}
.btn-primary:focus,
.btn-primary.focus {
  color: $white;
  background-color: #3d66c3;
  border-color: #2a4889;
}
.btn-primary:hover {
  color: $white;
  background-color: #3d66c3;
  border-color: #3a62bc;
}
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: #3d66c3;
  border-color: #3a62bc;
}
.btn-primary:active:hover,
.btn-primary:active:focus,
.btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus {
  color: $white;
  background-color: #3458a8;
  border-color: #2a4889;
}
.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle {
  background-image: none;
}
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary.focus {
  background-color: $text-grey;
  border-color: $text-grey;
}
.btn-primary .badge {
  color: $text-grey;
  background-color: $white;
}

.btn-success {
  color: $white;
  background-color: #49b218;
  border-color: #49b218;
}
.btn-success:focus,
.btn-success.focus {
  color: $white;
  background-color: #378512;
  border-color: #1b4209;
}
.btn-success:hover {
  color: $white;
  background-color: #378512;
  border-color: #337c11;
}
.btn-success:active,
.btn-success.active,
.open > .btn-success.dropdown-toggle {
  color: $white;
  background-color: #378512;
  border-color: #337c11;
}
.btn-success:active:hover,
.btn-success:active:focus,
.btn-success:active.focus,
.btn-success.active:hover,
.btn-success.active:focus,
.btn-success.active.focus,
.open > .btn-success.dropdown-toggle:hover,
.open > .btn-success.dropdown-toggle:focus,
.open > .btn-success.dropdown-toggle.focus {
  color: $white;
  background-color: #2a660e;
  border-color: #1b4209;
}
.btn-success:active,
.btn-success.active,
.open > .btn-success.dropdown-toggle {
  background-image: none;
}
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus {
  background-color: #49b218;
  border-color: #49b218;
}
.btn-success .badge {
  color: #49b218;
  background-color: $white;
}

.btn-info {
  color: $white;
  background-color: #4f7dd6;
  border-color: #4f7dd6;
}
.btn-info:focus,
.btn-info.focus {
  color: $white;
  background-color: #2e61c4;
  border-color: #1f4286;
}
.btn-info:hover {
  color: $white;
  background-color: #2e61c4;
  border-color: #2c5dbc;
}
.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
  color: $white;
  background-color: #2e61c4;
  border-color: #2c5dbc;
}
.btn-info:active:hover,
.btn-info:active:focus,
.btn-info:active.focus,
.btn-info.active:hover,
.btn-info.active:focus,
.btn-info.active.focus,
.open > .btn-info.dropdown-toggle:hover,
.open > .btn-info.dropdown-toggle:focus,
.open > .btn-info.dropdown-toggle.focus {
  color: $white;
  background-color: #2753a7;
  border-color: #1f4286;
}
.btn-info:active,
.btn-info.active,
.open > .btn-info.dropdown-toggle {
  background-image: none;
}
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled.focus,
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info.focus {
  background-color: #4f7dd6;
  border-color: #4f7dd6;
}
.btn-info .badge {
  color: #4f7dd6;
  background-color: $white;
}

.btn-warning {
  color: $white;
  background-color: #ff8f1f;
  border-color: #ff8f1f;
}
.btn-warning:focus,
.btn-warning.focus {
  color: $white;
  background-color: #eb7600;
  border-color: #9f4f00;
}
.btn-warning:hover {
  color: $white;
  background-color: #eb7600;
  border-color: #e17000;
}
.btn-warning:active,
.btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  color: $white;
  background-color: #eb7600;
  border-color: #e17000;
}
.btn-warning:active:hover,
.btn-warning:active:focus,
.btn-warning:active.focus,
.btn-warning.active:hover,
.btn-warning.active:focus,
.btn-warning.active.focus,
.open > .btn-warning.dropdown-toggle:hover,
.open > .btn-warning.dropdown-toggle:focus,
.open > .btn-warning.dropdown-toggle.focus {
  color: $white;
  background-color: #c76400;
  border-color: #9f4f00;
}
.btn-warning:active,
.btn-warning.active,
.open > .btn-warning.dropdown-toggle {
  background-image: none;
}
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #ff8f1f;
  border-color: #ff8f1f;
}
.btn-warning .badge {
  color: #ff8f1f;
  background-color: $white;
}

.btn-danger {
  color: $white;
  background-color: $red;
  border-color: $red;
}
.btn-danger:focus,
.btn-danger.focus {
  color: $white;
  background-color: #a72128;
  border-color: #671419;
}
.btn-danger:hover {
  color: $white;
  background-color: #a72128;
  border-color: #9f1f26;
}
.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  color: $white;
  background-color: #a72128;
  border-color: #9f1f26;
}
.btn-danger:active:hover,
.btn-danger:active:focus,
.btn-danger:active.focus,
.btn-danger.active:hover,
.btn-danger.active:focus,
.btn-danger.active.focus,
.open > .btn-danger.dropdown-toggle:hover,
.open > .btn-danger.dropdown-toggle:focus,
.open > .btn-danger.dropdown-toggle.focus {
  color: $white;
  background-color: #891b21;
  border-color: #671419;
}
.btn-danger:active,
.btn-danger.active,
.open > .btn-danger.dropdown-toggle {
  background-image: none;
}
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger.focus {
  background-color: $red;
  border-color: $red;
}
.btn-danger .badge {
  color: $red;
  background-color: $white;
}

.btn-link {
  color: $text-blue;
  font-weight: normal;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #2c3950;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus {
  color: #d9dce3;
  text-decoration: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.33333;
  border-radius: 20px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 0;
}

.btn-xs,
.btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 11px;
  line-height: 1.5;
  border-radius: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 5px;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}
.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  float: left;
}
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 2;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -5px;
}
.btn-toolbar:before,
.btn-toolbar:after {
  content: ' ';
  display: table;
}
.btn-toolbar:after {
  clear: both;
}
.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}
.btn-toolbar > .btn,
.btn-toolbar > .btn-group,
.btn-toolbar > .input-group {
  margin-left: 5px;
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-group > .btn-group {
  float: left;
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px;
}

.btn-group > .btn-lg + .dropdown-toggle,
.btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret,
.btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}

.dropup .btn-lg .caret,
.dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group,
.btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}

.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after {
  content: ' ';
  display: table;
}

.btn-group-vertical > .btn-group:after {
  clear: both;
}

.btn-group-vertical > .btn-group > .btn {
  float: none;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0;
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}
.btn-group-justified > .btn,
.btn-group-justified > .btn-group {
  float: none;
  display: table-cell;
  width: 1%;
}
.btn-group-justified > .btn-group .btn {
  width: 100%;
}
.btn-group-justified > .btn-group .dropdown-menu {
  left: auto;
}

[data-toggle='buttons'] > .btn input[type='radio'],
[data-toggle='buttons'] > .btn input[type='checkbox'],
[data-toggle='buttons'] > .btn-group > .btn input[type='radio'],
[data-toggle='buttons'] > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.input-group[class*='col-'] {
  float: none;
  padding-left: 0;
  padding-right: 0;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}
.input-group .form-control:focus {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  color: #141419;
  text-align: center;
  background-color: white;
  border: 1px solid #dee5e7;
  border-radius: 0;
}
.input-group-addon.input-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 5px 10px;
  font-size: 11px;
  border-radius: 2px;
}
.input-group-addon.input-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 10px 16px;
  font-size: 15px;
  border-radius: 4px;
}
.input-group-addon input[type='radio'],
.input-group-addon input[type='checkbox'] {
  margin-top: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:hover,
.input-group-btn > .btn:focus,
.input-group-btn > .btn:active {
  z-index: 2;
}
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group {
  margin-right: -1px;
}
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.container > .navbar-header,
.container > .navbar-collapse,
.container-fluid > .navbar-header,
.container-fluid > .navbar-collapse {
  margin-right: -25px;
  margin-left: -25px;
}
@media (min-width: 768px) {
  .container > .navbar-header,
  .container > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}
@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0;
  }
}

.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}
@media (min-width: 768px) {
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}

.navbar-brand {
  float: left;
  padding: 30px 25px;
  font-size: 15px;
  line-height: 18px;
  height: 80px;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-brand > img {
  display: block;
}
@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: -25px;
  }
}

.breadcrumb {
  padding: 8px 5px;
  margin-bottom: 18px;
  list-style: none;
  background-color: transparent;
  border-radius: 3px;
}
.breadcrumb > li {
  display: inline-block;
}
.breadcrumb > li + li:before {
  content: '/ ';
  padding: 0 5px;
  color: #e4ecef;
}
.breadcrumb > .active {
  color: #2c3950;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 18px 0;
  border-radius: 3px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.5;
  text-decoration: none;
  color: $text-grey;
  background-color: $white;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  z-index: 2;
  color: #2c3950;
  background-color: #f7f7f7;
  border-color: #ddd;
}
.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  z-index: 3;
  color: $white;
  background-color: $text-grey;
  border-color: $text-grey;
  cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #d9dce3;
  background-color: $white;
  border-color: #ddd;
  cursor: not-allowed;
}

.pagination-lg > li > a,
.pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 15px;
  line-height: 1.33333;
}

.pagination-lg > li:first-child > a,
.pagination-lg > li:first-child > span {
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.pagination-lg > li:last-child > a,
.pagination-lg > li:last-child > span {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.pagination-sm > li > a,
.pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 11px;
  line-height: 1.5;
}

.pagination-sm > li:first-child > a,
.pagination-sm > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.pagination-sm > li:last-child > a,
.pagination-sm > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.label:empty {
  display: none;
}
.btn .label {
  position: relative;
  top: -1px;
}

a.label:hover,
a.label:focus {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}

.label-default {
  background-color: #d9dce3;
}
.label-default[href]:hover,
.label-default[href]:focus {
  background-color: #bcc1cd;
}

.label-primary {
  background-color: rgba(131, 105, 236, 0.9);
}
.label-primary[href]:hover,
.label-primary[href]:focus {
  background-color: rgba(94, 60, 230, 0.9);
}

.label-success {
  background-color: #0ab3a0;
}
.label-success[href]:hover,
.label-success[href]:focus {
  background-color: #078375;
}

.label-info {
  background-color: rgba(44, 156, 219, 0.9);
}
.label-info[href]:hover,
.label-info[href]:focus {
  background-color: rgba(31, 127, 181, 0.9);
}

.label-warning {
  background-color: rgba(231, 95, 31, 0.9);
}
.label-warning[href]:hover,
.label-warning[href]:focus {
  background-color: rgba(191, 75, 20, 0.9);
}

.label-danger {
  background-color: #ff0000;
}
.label-danger[href]:hover,
.label-danger[href]:focus {
  background-color: #cc0000;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  border-radius: 10px;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.btn-xs .badge,
.btn-group-xs > .btn .badge,
.btn-group-xs > .btn .badge {
  top: 0;
  padding: 1px 5px;
}
.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: $text-blue;
  background-color: $white;
}
.list-group-item > .badge {
  float: right;
}
.list-group-item > .badge + .badge {
  margin-right: 5px;
}
.nav-pills > li > a > .badge {
  margin-left: 3px;
}

a.badge:hover,
a.badge:focus {
  color: $white;
  text-decoration: none;
  cursor: pointer;
}

.alert {
  padding: 15px;
  margin-bottom: 18px;
  border: 1px solid transparent;
  border-radius: 3px;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}
.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #49b218;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #378512;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #ec9d00;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #b97b00;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #e60012;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #b3000e;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  overflow: hidden;
  height: 18px;
  margin-bottom: 18px;
  background-color: #d9d9d9;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 11px;
  line-height: 18px;
  color: $white;
  text-align: center;
  background-color: #2c3950;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
}

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: #49b218;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-info {
  background-color: #4f7dd6;
}
.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-warning {
  background-color: #ff8f1f;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress-bar-danger {
  background-color: $red;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.media {
  margin-top: 15px;
}
.media:first-child {
  margin-top: 0;
}

.media,
.media-body {
  zoom: 1;
  overflow: hidden;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;
}
.media-object.img-thumbnail {
  max-width: none;
}

.media-right,
.media > .pull-right {
  padding-left: 10px;
}

.media-left,
.media > .pull-left {
  padding-right: 10px;
}

.media-left,
.media-right,
.media-body {
  display: table-cell;
  vertical-align: top;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.panel {
  margin-bottom: 18px;
  background-color: $white;
  border: 1px solid transparent;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
  padding: 15px;
}
.panel-body:before,
.panel-body:after {
  content: ' ';
  display: table;
}
.panel-body:after {
  clear: both;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.panel-heading > .dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: inherit;
}
.panel-title > a,
.panel-title > small,
.panel-title > .small,
.panel-title > small > a,
.panel-title > .small > a {
  color: inherit;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.panel > .list-group,
.panel > .panel-collapse > .list-group {
  margin-bottom: 0;
}
.panel > .list-group .list-group-item,
.panel > .panel-collapse > .list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}
.panel > .list-group:first-child .list-group-item:first-child,
.panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.panel > .list-group:last-child .list-group-item:last-child,
.panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.panel > .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.panel-heading + .list-group .list-group-item:first-child {
  border-top-width: 0;
}

.list-group + .panel-footer {
  border-top-width: 0;
}

.panel > .table,
.panel > .table-responsive > .table,
.panel > .panel-collapse > .table {
  margin-bottom: 0;
}
.panel > .table caption,
.panel > .table-responsive > .table caption,
.panel > .panel-collapse > .table caption {
  padding-left: 15px;
  padding-right: 15px;
}

.panel > .table:first-child,
.panel > .table-responsive:first-child > .table:first-child {
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}
.panel > .table:first-child > thead:first-child > tr:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:first-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:first-child {
  border-top-left-radius: 2px;
}
.panel > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table:first-child > tbody:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > thead:first-child > tr:first-child th:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child td:last-child,
.panel > .table-responsive:first-child > .table:first-child > tbody:first-child > tr:first-child th:last-child {
  border-top-right-radius: 2px;
}

.panel > .table:last-child,
.panel > .table-responsive:last-child > .table:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:first-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:first-child {
  border-bottom-left-radius: 2px;
}
.panel > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table:last-child > tfoot:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tbody:last-child > tr:last-child th:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child td:last-child,
.panel > .table-responsive:last-child > .table:last-child > tfoot:last-child > tr:last-child th:last-child {
  border-bottom-right-radius: 2px;
}

.panel > .panel-body + .table,
.panel > .panel-body + .table-responsive,
.panel > .table + .panel-body,
.panel > .table-responsive + .panel-body {
  border-top: 1px solid #d9dce3;
}

.panel > .table > tbody:first-child > tr:first-child th,
.panel > .table > tbody:first-child > tr:first-child td {
  border-top: 0;
}

.panel > .table-bordered,
.panel > .table-responsive > .table-bordered {
  border: 0;
}
.panel > .table-bordered > thead > tr > th:first-child,
.panel > .table-bordered > thead > tr > td:first-child,
.panel > .table-bordered > tbody > tr > th:first-child,
.panel > .table-bordered > tbody > tr > td:first-child,
.panel > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-bordered > tfoot > tr > td:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:first-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:first-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.panel > .table-bordered > thead > tr > th:last-child,
.panel > .table-bordered > thead > tr > td:last-child,
.panel > .table-bordered > tbody > tr > th:last-child,
.panel > .table-bordered > tbody > tr > td:last-child,
.panel > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-bordered > tfoot > tr > td:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > th:last-child,
.panel > .table-responsive > .table-bordered > thead > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tbody > tr > td:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > th:last-child,
.panel > .table-responsive > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.panel > .table-bordered > thead > tr:first-child > td,
.panel > .table-bordered > thead > tr:first-child > th,
.panel > .table-bordered > tbody > tr:first-child > td,
.panel > .table-bordered > tbody > tr:first-child > th,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > td,
.panel > .table-responsive > .table-bordered > thead > tr:first-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:first-child > th {
  border-bottom: 0;
}
.panel > .table-bordered > tbody > tr:last-child > td,
.panel > .table-bordered > tbody > tr:last-child > th,
.panel > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-bordered > tfoot > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tbody > tr:last-child > th,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > td,
.panel > .table-responsive > .table-bordered > tfoot > tr:last-child > th {
  border-bottom: 0;
}

.panel > .table-responsive {
  border: 0;
  margin-bottom: 0;
}

.panel-group {
  margin-bottom: 18px;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 3px;
}
.panel-group .panel + .panel {
  margin-top: 5px;
}
.panel-group .panel-heading {
  border-bottom: 0;
}
.panel-group .panel-heading + .panel-collapse > .panel-body,
.panel-group .panel-heading + .panel-collapse > .list-group {
  border-top: 1px solid #ddd;
}
.panel-group .panel-footer {
  border-top: 0;
}
.panel-group .panel-footer + .panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.panel-default {
  border-color: #ddd;
}
.panel-default > .panel-heading {
  color: $grey-9b;
  background-color: #f5f5f5;
  border-color: #ddd;
}
.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}
.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: $grey-9b;
}
.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-primary {
  border-color: #2c3950;
}
.panel-primary > .panel-heading {
  color: $white;
  background-color: #2c3950;
  border-color: #2c3950;
}
.panel-primary > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #2c3950;
}
.panel-primary > .panel-heading .badge {
  color: #2c3950;
  background-color: $white;
}
.panel-primary > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #2c3950;
}

.panel-success {
  border-color: #d6e9c6;
}
.panel-success > .panel-heading {
  color: #49b218;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.panel-success > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #d6e9c6;
}
.panel-success > .panel-heading .badge {
  color: #dff0d8;
  background-color: #49b218;
}
.panel-success > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}
.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #bce8f1;
}
.panel-info > .panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}
.panel-info > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}
.panel-warning > .panel-heading {
  color: #ec9d00;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #faebcc;
}
.panel-warning > .panel-heading .badge {
  color: #fcf8e3;
  background-color: #ec9d00;
}
.panel-warning > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}
.panel-danger > .panel-heading {
  color: #e60012;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.panel-danger > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ebccd1;
}
.panel-danger > .panel-heading .badge {
  color: #f2dede;
  background-color: #e60012;
}
.panel-danger > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ebccd1;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.close {
  float: right;
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  color: $black;
  text-shadow: 0 1px 0 $white;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.close:hover,
.close:focus {
  color: $black;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: $white;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: $black;
}
.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.modal-header:before,
.modal-header:after {
  content: ' ';
  display: table;
}
.modal-header:after {
  clear: both;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.5;
}
.modal-footer:before,
.modal-footer:after {
  content: ' ';
  display: table;
}
.modal-footer:after {
  clear: both;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 530px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: $libre;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 11px;
  opacity: 0;
  filter: alpha(opacity=0);
}
.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.tooltip.top {
  margin-top: -3px;
  padding: 5px 0;
}
.tooltip.right {
  margin-left: 3px;
  padding: 0 5px;
}
.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0;
}
.tooltip.left {
  margin-left: -3px;
  padding: 0 5px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: $white;
  text-align: center;
  background-color: $black;
  border-radius: 3px;
  word-break: break-word;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: $black;
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: $black;
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: $black;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: $black;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: $black;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: $black;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: $black;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: $black;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1020;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: $libre;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid #e1e1e1;
  border-radius: 20px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.popover.top {
  margin-top: -10px;
}
.popover.right {
  margin-left: 10px;
}
.popover.bottom {
  margin-top: 10px;
}
.popover.left {
  margin-left: -10px;
}

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 12px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 19px 19px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

.popover > .arrow,
.popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover > .arrow {
  border-width: 11px;
}

.popover > .arrow:after {
  border-width: 10px;
  content: '';
}

.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: #e1e1e1;
  bottom: -11px;
}
.popover.top > .arrow:after {
  content: ' ';
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: $white;
}

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: #e1e1e1;
}
.popover.right > .arrow:after {
  content: ' ';
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: $white;
}

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: #e1e1e1;
  top: -11px;
}
.popover.bottom > .arrow:after {
  content: ' ';
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: $white;
}

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: #e1e1e1;
}
.popover.left > .arrow:after {
  content: ' ';
  right: 1px;
  border-right-width: 0;
  border-left-color: $white;
  bottom: -10px;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
  line-height: 1;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-inner > .item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -moz-transition: -moz-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px;
  }
  .carousel-inner > .item.next,
  .carousel-inner > .item.active.right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    left: 0;
  }
  .carousel-inner > .item.prev,
  .carousel-inner > .item.active.left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    left: 0;
  }
  .carousel-inner > .item.next.left,
  .carousel-inner > .item.prev.right,
  .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: $white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: transparent;
}
.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}
.carousel-control.right {
  left: auto;
  right: 0;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}
.carousel-control:hover,
.carousel-control:focus {
  outline: 0;
  color: $white;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  z-index: 5;
  display: inline-block;
}
.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
  margin-left: -10px;
}
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
  margin-right: -10px;
}
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif;
}
.carousel-control .icon-prev:before {
  content: '\2039';
}
.carousel-control .icon-next:before {
  content: '\203a';
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;
}
.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 1px solid $white;
  border-radius: 10px;
  cursor: pointer;
  background-color: $black \9;
  background-color: transparent;
}
.carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: $white;
}

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-prev,
  .carousel-control .icon-next {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }
  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }
  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px;
  }
  .carousel-indicators {
    bottom: 20px;
  }
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

body,
html {
  font-size: 12px;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .container-fluid {
  width: 100%;
  max-width: 1800px;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 1441px) {
  #app,
  body {
    font-size: 14px;
  }
}

@media (min-width: 1800px) {
  #app,
  body {
    font-size: 15px;
  }
}

body:-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.white-bg {
  background: $white;
}

.content-wrapper {
  min-height: calc(100vh - 160px);
}

a {
  cursor: pointer;
}
a:focus {
  outline: 0;
}

.buffer-bottom {
  margin-bottom: 10px;
}

.buffer-top {
  margin-top: 10px;
}

.no-padding {
  padding: 0 !important;
}

.small,
small {
  font-size: 0.88em;
}

.text-muted {
  color: #a4a4ab;
}

.text-capitalize {
  text-transform: capitalize;
}

.page-title {
  margin: 0;
  padding: 1em 0.5em;
  color: #002a3a;
  font-size: 2em;
}

.navbar-nav .btn.btn-primary {
  margin: 15px 5px;
  padding: 15px 30px;
  background-color: #141419;
  border-color: #141419;
}
.navbar-nav .btn.btn-primary:hover {
  background-color: #4f586c;
}

.navbar-nav > li > a {
  font-family: $montserrat;
  font-weight: 500;
  text-transform: uppercase;
}
@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding: 35px 15px 0;
  }
}
.navbar-nav > li > a .fa {
  padding-right: 5px;
}

.navbar-nav > li > .dropdown-menu {
  margin-top: -10px;
}
.navbar-nav > li > .dropdown-menu:before {
  right: 10px;
}

.navbar-nav > li.with-icon > a {
  padding: 28px 15px;
  color: #8e98ac;
  font-size: 24px;
}
.navbar-nav > li.with-icon > a:hover {
  color: $grey-9b;
}
.navbar-nav > li.with-icon > a.dropdown-user {
  font-size: 27px;
}

.navbar-nav > li.with-icon:last-child > a {
  padding-right: 0;
}

.navbar-nav > li.with-icon .notification {
  position: relative;
}
.navbar-nav > li.with-icon .notification:before {
  position: absolute;
  height: 15px;
  width: 15px;
  background: $red;
  border: 3px solid #2c3950;
  border-radius: 50%;
  content: '';
  left: 32px;
  top: 26px;
}

.navbar-text {
  margin: 22px 15px;
}
.navbar-text > span {
  display: block;
  color: #808aa1;
  font-family: $montserrat;
  font-size: 12px;
  font-weight: 500;
}

.navbar-brand {
  margin-right: 25px;
  padding: 20px 5px;
}

h1.navbar-text {
  margin: 27px 0;
  padding: 0 0 0 25px;
  border-left: 1px solid #edf1f2;
  font-size: 24px;
}

.navbar-inverse {
  border: 0;
}

.navbar-inverse .navbar-nav.navbar-right > .open > a,
.navbar-inverse .navbar-nav.navbar-right > .open > a:focus,
.navbar-inverse .navbar-nav.navbar-right > .open > a:hover {
  background-color: transparent;
}

@media (min-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
  }
}

.sub-nav {
  padding: 3em 0 1.5em;
}
.sub-nav h2 {
  margin: 0;
  padding-bottom: 10px;
  font-size: 1.7em;
}
.sub-nav h3 {
  margin: 0;
  padding: 20px 0;
  font-size: 1.1em;
}
.sub-nav .breadcrumb {
  margin: 0;
  padding: 0;
}
.sub-nav .btn {
  margin-left: 0.5em;
}

.btn {
  font-family: $montserrat;
  font-weight: 500;
  letter-spacing: 0.1em;
  padding: 12px 40px;
  text-transform: uppercase;
  transition: all 0.25s ease;
}
@media (min-width: 992px) {
  .btn {
    font-size: 13px;
  }
}
@media (min-width: 1600px) {
  .btn {
    font-size: 14px;
  }
}
.btn.disabled,
.btn:disabled {
  opacity: 0.64;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 4.5px 18px;
}
.btn.active,
.btn:active {
  box-shadow: none;
}
.btn.btn-actions {
  font-size: 1.2em;
  line-height: 3em;
}
.btn.btn-link {
  color: #2c3950;
  letter-spacing: 0;
  padding: 0 5px;
  text-transform: none;
  font-size: 1em;
  vertical-align: middle;
}
.btn.btn-link:hover {
  color: #2c3950;
  text-decoration: none;
}
.btn.btn-link.active {
  font-weight: bold;
  color: $text-blue;
}
.btn.btn-link.btn-link-danger {
  color: $red;
}
.btn:focus {
  outline: 0 !important;
}
.btn .fa,
.btn [data-prefix='fa'],
.btn [data-prefix='fas'] {
  margin-right: 8px;
}

.btn-primary-red {
  color: $white;
  background-color: $red;
  border-color: $red;
}
.btn-primary-red:focus,
.btn-primary-red.focus {
  color: $white;
  background-color: #a72128;
  border-color: #671419;
}
.btn-primary-red:hover {
  color: $white;
  background-color: #a72128;
  border-color: #9f1f26;
}
.btn-primary-red:active,
.btn-primary-red.active,
.open > .btn-primary-red.dropdown-toggle {
  color: $white;
  background-color: #a72128;
  border-color: #9f1f26;
}
.btn-primary-red:active:hover,
.btn-primary-red:active:focus,
.btn-primary-red:active.focus,
.btn-primary-red.active:hover,
.btn-primary-red.active:focus,
.btn-primary-red.active.focus,
.open > .btn-primary-red.dropdown-toggle:hover,
.open > .btn-primary-red.dropdown-toggle:focus,
.open > .btn-primary-red.dropdown-toggle.focus {
  color: $white;
  background-color: #891b21;
  border-color: #671419;
}
.btn-primary-red:active,
.btn-primary-red.active,
.open > .btn-primary-red.dropdown-toggle {
  background-image: none;
}
.btn-primary-red.disabled:hover,
.btn-primary-red.disabled:focus,
.btn-primary-red.disabled.focus,
.btn-primary-red[disabled]:hover,
.btn-primary-red[disabled]:focus,
.btn-primary-red[disabled].focus,
fieldset[disabled] .btn-primary-red:hover,
fieldset[disabled] .btn-primary-red:focus,
fieldset[disabled] .btn-primary-red.focus {
  background-color: $red;
  border-color: $red;
}
.btn-primary-red .badge {
  color: $red;
  background-color: $white;
}
.btn-primary-red:hover {
  background-color: #bd252d;
}

.btn-secondary {
  color: $text-grey;
  background-color: $white;
  border-color: $text-grey;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: $text-grey;
  background-color: #e6e6e6;
  border-color: #2f3440;
}
/* .btn-secondary:hover {
    color: $text-grey;
    background-color: #e6e6e6;
    border-color: #4b5366; } */
.btn-secondary:active,
.btn-secondary.active,
.open > .btn-secondary.dropdown-toggle {
  color: $text-grey;
  background-color: #e6e6e6;
  border-color: #4b5366;
}
.btn-secondary:active:hover,
.btn-secondary:active:focus,
.btn-secondary:active.focus,
.btn-secondary.active:hover,
.btn-secondary.active:focus,
.btn-secondary.active.focus,
.open > .btn-secondary.dropdown-toggle:hover,
.open > .btn-secondary.dropdown-toggle:focus,
.open > .btn-secondary.dropdown-toggle.focus {
  color: $text-grey;
  background-color: #d4d4d4;
  border-color: #2f3440;
}
.btn-secondary:active,
.btn-secondary.active,
.open > .btn-secondary.dropdown-toggle {
  background-image: none;
}
.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled.focus,
.btn-secondary[disabled]:hover,
.btn-secondary[disabled]:focus,
.btn-secondary[disabled].focus,
fieldset[disabled] .btn-secondary:hover,
fieldset[disabled] .btn-secondary:focus,
fieldset[disabled] .btn-secondary.focus {
  background-color: $white;
  border-color: $text-grey;
}
.btn-secondary .badge {
  color: $white;
  background-color: $text-grey;
}
/* .btn-secondary:hover {
    background-color: $white;
    color: #141b2b; } */

.btn-tertiary {
  color: $white;
  background-color: $text-blue;
  border-color: $text-blue;
}
.btn-tertiary:focus,
.btn-tertiary.focus {
  color: $white;
  background-color: #3d66c3;
  border-color: #2a4889;
}
.btn-tertiary:hover {
  color: $white;
  background-color: #3d66c3;
  border-color: #3a62bc;
}
.btn-tertiary:active,
.btn-tertiary.active,
.open > .btn-tertiary.dropdown-toggle {
  color: $white;
  background-color: #3d66c3;
  border-color: #3a62bc;
}
.btn-tertiary:active:hover,
.btn-tertiary:active:focus,
.btn-tertiary:active.focus,
.btn-tertiary.active:hover,
.btn-tertiary.active:focus,
.btn-tertiary.active.focus,
.open > .btn-tertiary.dropdown-toggle:hover,
.open > .btn-tertiary.dropdown-toggle:focus,
.open > .btn-tertiary.dropdown-toggle.focus {
  color: $white;
  background-color: #3458a8;
  border-color: #2a4889;
}
.btn-tertiary:active,
.btn-tertiary.active,
.open > .btn-tertiary.dropdown-toggle {
  background-image: none;
}
.btn-tertiary.disabled:hover,
.btn-tertiary.disabled:focus,
.btn-tertiary.disabled.focus,
.btn-tertiary[disabled]:hover,
.btn-tertiary[disabled]:focus,
.btn-tertiary[disabled].focus,
fieldset[disabled] .btn-tertiary:hover,
fieldset[disabled] .btn-tertiary:focus,
fieldset[disabled] .btn-tertiary.focus {
  background-color: $text-blue;
  border-color: $text-blue;
}
.btn-tertiary .badge {
  color: $text-blue;
  background-color: $white;
}
.btn-tertiary:hover {
  background-color: #5176c9;
}
.btn-tertiary.focus,
.btn-tertiary:focus {
  background-color: #5176c9;
  box-shadow: none;
  outline: 0;
}

.btn-filter {
  color: $text-grey;
  background-color: $white;
  border-color: $text-grey;
}
.btn-filter:focus,
.btn-filter.focus {
  color: $text-grey;
  background-color: #e6e6e6;
  border-color: #2f3440;
}
.btn-filter:hover {
  color: $text-grey;
  background-color: #e6e6e6;
  border-color: #4b5366;
}
.btn-filter:active,
.btn-filter.active,
.open > .btn-filter.dropdown-toggle {
  color: $text-grey;
  background-color: #e6e6e6;
  border-color: #4b5366;
}
.btn-filter:active:hover,
.btn-filter:active:focus,
.btn-filter:active.focus,
.btn-filter.active:hover,
.btn-filter.active:focus,
.btn-filter.active.focus,
.open > .btn-filter.dropdown-toggle:hover,
.open > .btn-filter.dropdown-toggle:focus,
.open > .btn-filter.dropdown-toggle.focus {
  color: $text-grey;
  background-color: #d4d4d4;
  border-color: #2f3440;
}
.btn-filter:active,
.btn-filter.active,
.open > .btn-filter.dropdown-toggle {
  background-image: none;
}
.btn-filter.disabled:hover,
.btn-filter.disabled:focus,
.btn-filter.disabled.focus,
.btn-filter[disabled]:hover,
.btn-filter[disabled]:focus,
.btn-filter[disabled].focus,
fieldset[disabled] .btn-filter:hover,
fieldset[disabled] .btn-filter:focus,
fieldset[disabled] .btn-filter.focus {
  background-color: $white;
  border-color: $text-grey;
}
.btn-filter .badge {
  color: $white;
  background-color: $text-grey;
}
.btn-filter:active:hover,
.btn-filter:hover {
  background: $white;
}
.btn-filter:hover {
  border-color: #1a222f;
}
.btn-filter:active,
.btn-filter:focus,
.btn-filter:active:focus {
  background: #f1f6f9;
  outline: 0;
}

.btn-default.active,
.btn-default.active.focus,
.btn-default.active.hover,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active:hover,
.btn-default:active,
.btn-default:hover {
  background-color: $white;
  border-color: #b6b6b6;
  box-shadow: none;
}

.open > .btn-default.dropdown-toggle,
.open > .btn-default.dropdown-toggle.focus,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle:hover {
  background: $white;
  box-shadow: none;
}

.open > .btn-secondary.dropdown-toggle.focus,
.open > .btn-secondary.dropdown-toggle:focus,
.open > .btn-secondary.dropdown-toggle:hover {
  background: #edf0f9;
}

.dropdown-columns .dropdown-menu {
  padding: 0 15px;
  right: 5px;
}

.dropdown-columns .dropdown-menu:before {
  right: 2px;
  top: -10px;
}

.dropdown-columns > .dropdown-toggle {
  background: none;
  border: 0;
  font-size: 1.4em;
}

.dropdown-columns.open > .btn-default.dropdown-toggle,
.dropdown-columns.open > .btn-default.dropdown-toggle:active:focus,
.dropdown-columns .btn-default:active:focus {
  background: none;
}

.dropdown-columns .caret {
  display: none;
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  outline: 0;
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
}

.btn-group {
  margin-right: 10px;
}
.btn-group .btn {
  padding: 10px 20px;
  font-size: 17px;
  color: #c4c8cb;
  border-color: #c4c8cb;
}
.btn-group .btn.active {
  background: $white;
  border-color: #b2c2e7;
  color: $text-blue;
  box-shadow: none;
}
.btn-group .btn .fa {
  margin: 0;
}

.action-buttons {
  display: flex;
  flex-direction: column-reverse;
  margin-top: 40px;
  text-align: center;
}
.action-buttons .btn {
  flex: 1;
}
.action-buttons .btn-link {
  font-weight: normal;
}
.action-buttons.full,
.action-buttons.left,
.action-buttons.right {
  margin-top: 2px;
}
.action-buttons.full .btn,
.action-buttons.left .btn,
.action-buttons.right .btn {
  margin: 10px 0;
}

@media (min-width: 768px) {
  .action-buttons {
    display: block;
    text-align: right;
  }
  .action-buttons .btn {
    flex: unset;
  }
  .action-buttons.right {
    text-align: right;
  }
  .action-buttons.right .btn {
    margin-left: 10px;
  }
}

h1,
h2 {
  font-weight: 400;
}

h5 {
  text-transform: none;
  font-size: 1.2em;
  font-weight: 400;
}

.strong {
  font-weight: bold;
}

.btn.dropdown-toggle {
  padding: 8.5px 15px;
  border-color: #ddd;
}

.dropdown-toggle:focus {
  background: none;
}

.label {
  font-family: $montserrat;
  border-radius: 1.5em;
  font-size: 0.9em;
  text-transform: uppercase;
  vertical-align: middle;
  padding: 0.6em 1.33em 0.5em;
  font-weight: 500;
}

.label-default {
  color: $dark-grey;
}

.label-at-risk {
  background-color: #ff8f1f;
}
.label-at-risk[href]:hover,
.label-at-risk[href]:focus {
  background-color: #eb7600;
}

.label-inverse {
  background: $white;
}
.label-inverse.label-default {
  border: 1px solid #b6b6b6;
  color: $grey-9b;
}
.label-inverse.label-primary {
  border: 1px solid rgba(131, 105, 236, 0.9);
  color: rgba(131, 105, 236, 0.9);
}
.label-inverse.label-success {
  border: 1px solid #49b218;
  color: #49b218;
}
.label-inverse.label-info {
  border: 1px solid rgba(44, 156, 219, 0.9);
  color: rgba(44, 156, 219, 0.9);
}
.label-inverse.label-warning {
  border: 1px solid #ff8f1f;
  color: #ff8f1f;
}
.label-inverse.label-at-risk {
  border: 1px solid #ff8f1f;
  color: #ff8f1f;
}
.label-inverse.label-danger {
  border: 1px solid #ff0000;
  color: #ff0000;
}
.label-inverse.label-in-progress {
  border: 1px solid #ff8f1f;
  color: #ff8f1f;
}

.label-bullet {
  background: transparent;
  border: 0;
  text-align: left;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.label-bullet:before {
  content: '\2022';
  padding-right: 5px;
  font-size: 3em;
  line-height: 18px;
  float: left;
}
.label-bullet.label-default {
  color: $grey-9b;
}
.label-bullet.label-gray {
  color: $dark-grey;
}
.label-bullet.label-primary {
  color: rgba(131, 105, 236, 0.9);
}
.label-bullet.label-success {
  color: #49b218;
}
.label-bullet.label-info {
  color: rgba(44, 156, 219, 0.9);
}
.label-bullet.label-warning {
  color: #ff8f1f;
}
.label-bullet.label-at-risk {
  color: #ff8f1f;
}
.label-bullet.label-danger {
  color: #ff0000;
}
.label-bullet.label-in-progress {
  color: $dark-grey;
}

*:focus {
  box-shadow: none;
  outline: 0;
}

.date {
  white-space: nowrap;
}

.time {
  display: block;
  color: #acacb4;
}
@media (min-width: 1200px) {
  .time {
    display: inline-block;
  }
}

.actions-list {
  border: 0;
}
.actions-list .popover-content {
  padding: 10px 0;
  min-width: 160px;
}
.actions-list .btn.btn-link {
  padding: 5px 15px;
}
.actions-list .btn.btn-link svg {
  margin-right: 5px;
}
.actions-list .btn.btn-link:hover {
  text-decoration: none;
}
.actions-list li:hover {
  background: #f1f6f9;
}

.react-bs-table-container .react-bs-table {
  border: 1px solid #dde0e6;
}

.react-bs-table-container .react-bs-container-header {
  background: #f1f6f9;
}

.react-bs-table-container .react-bs-container-body {
  background: $white;
}

.react-bs-table-container .table {
  table-layout: fixed;
}
.react-bs-table-container .table thead {
  font-size: 0.9em;
  text-transform: uppercase;
}
.react-bs-table-container .table thead th {
  border-width: 1px;
  color: #002a3a;
  font-family: $montserrat;
  font-weight: 500;
  vertical-align: middle;
  white-space: nowrap;
}
.react-bs-table-container .table thead th .btn-link,
.react-bs-table-container .table thead th .btn-link:focus {
  display: block;
  margin: -18px;
  padding: 18px;
  font-size: 1.2em;
  outline: 0;
}
.react-bs-table-container .table thead th:hover {
  cursor: pointer;
}
.react-bs-table-container .table thead .dropdown-menu {
  right: -12px;
  text-transform: none;
  top: 32px;
}
.react-bs-table-container .table tbody tr > td {
  border-bottom: 1px solid #eee;
  border-top: 0;
  vertical-align: middle;
}
.react-bs-table-container .table tbody tr > td:focus {
  outline: 0;
}
.react-bs-table-container .table tbody .btn.dropdown-toggle {
  padding: 0;
}
.react-bs-table-container .table tbody .actions {
  padding: 10px 0;
  min-width: 100px;
  right: -12px;
}
.react-bs-table-container .table .expand-row {
  position: relative;
  background: #fafafa;
}
.react-bs-table-container .table .expand-row div td,
.react-bs-table-container .table .expand-row div th {
  padding: 5px 20px;
}
.react-bs-table-container .table .expand-row div td:first-child,
.react-bs-table-container .table .expand-row div th:first-child {
  padding-left: 45px;
}
.react-bs-table-container .table .expand-row div th {
  padding-top: 20px;
}
.react-bs-table-container .table .expand-row[hidden] {
  display: table-row !important;
  width: 100%;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}
.react-bs-table-container .table .expand-row.expand-row-collapsed div {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 200ms ease;
}
.react-bs-table-container .table .expand-row.expand-row-expanded div {
  padding: 0;
  max-height: 99999px;
  opacity: 1;
  transition: all 500ms ease;
}
.react-bs-table-container .table .expand-row > td {
  position: relative;
  padding: 0;
  background: #edf1f2;
}
.react-bs-table-container .table .expand-row > td:first-child:before {
  position: absolute;
  height: 0;
  width: 0;
  border-bottom: 10px solid #edf1f2;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  content: '';
  left: 50px;
  top: -10px;
}
.react-bs-table-container .table .expand-row thead {
  background: none;
}
.react-bs-table-container .table .expand-row table {
  width: 100%;
  background: none;
  border-bottom: 15px solid transparent;
}
.react-bs-table-container .table .expand-row table + table {
  border-top: 1px solid #dee5e7;
}
.react-bs-table-container .table .expand-row table th {
  color: $dark-grey;
}
.react-bs-table-container .table .expand-row table th:first-child {
  width: 420px;
}
.react-bs-table-container .table .expand-row table td {
  position: relative;
  border: 0;
}
.react-bs-table-container .table .expand-row .text-muted {
  color: $grey-9b;
}
.react-bs-table-container .table .expand-row .actions {
  padding: 0;
  text-align: center;
}

.react-bs-table-container .table-header-wrapper .table {
  margin: 0;
}
.react-bs-table-container .table-header-wrapper .table thead > tr > th {
  border-bottom: 0;
}

.react-bs-table-container .react-bs-table-expand-cell {
  text-align: center;
}

.react-bs-table-container .react-bootstrap-table-page-btns-ul {
  float: right;
}

.react-bs-table-container .react-bs-table-sizePerPage-dropdown.open > .dropdown-menu {
  top: 30px;
}

.react-bs-table-container .react-bs-table-sizePerPage-dropdown .dropdown-menu:before {
  display: none;
}

.react-bs-table-container .order > .dropup,
.react-bs-table-container .order > .dropdown {
  display: none;
}

.react-bs-table-container .order > .caret {
  margin: 0 0 0 5px !important;
  color: #002a3a;
}

.react-bs-table-search-form {
  position: relative;
  width: 300px;
  float: right;
}
.react-bs-table-search-form.form-group-sm .form-control {
  padding: 6px 10px 6px 30px;
  height: 40px;
  background: $white;
  font-size: 12px;
}
.react-bs-table-search-form:before {
  position: absolute;
  color: #cfd5dd;
  content: '\f002';
  font-family: $f-awesome;
  left: 10px;
  top: 10px;
}

.react-bs-table-sizePerPage-dropdown .btn {
  border-radius: 3px;
}

.react-bs-table-expand-cell .fa {
  color: $text-grey;
}

.breadcrumb {
  display: inline-block;
  font-family: $montserrat;
  text-transform: uppercase;
  font-weight: 500;
}
.breadcrumb > .active {
  color: #2c3950;
}
.breadcrumb > li > a {
  color: $text-grey;
}
.breadcrumb > li + li:before {
  font-family: $f-awesome;
  padding: 0 10px;
  content: '\f105';
  color: $text-grey;
}

.loading input,
.loading textarea {
  color: #ccc;
  cursor: not-allowed !important;
}

.form-control {
  box-shadow: none;
  font-size: 1em;
}
@media (min-width: 768px) {
  .form-control {
    max-width: none;
  }
}
.form-control:focus,
.form-control input[type='checkbox']:focus,
.form-control input[type='file']:focus,
.form-control input[type='radio']:focus {
  border-color: #d5dfe1;
  box-shadow: none;
}
.form-control.disabled,
.form-control:disabled {
  color: #b6b6b6;
}

.help-block {
  font-size: 12px;
}

.form-control-static {
  min-height: 0;
  padding: 0;
}

.control-label {
  display: block;
  font-family: $montserrat;
  font-weight: 500;
}

.form-horizontal .checkbox,
.form-horizontal .control-label {
  padding-top: 6px;
}
.form-horizontal .checkbox .checkbox-inline,
.form-horizontal .control-label .checkbox-inline {
  margin-left: 10px;
}

.recipients {
  line-height: 3em;
}
.recipients .label {
  border: 1px solid transparent;
  margin-right: 3px;
}
.recipients .label.selected {
  background-color: #f2c7d0;
  border: 1px solid #e89ead;
}

.input-group .form-control {
  z-index: auto;
}

.general-comments {
  margin-top: 5px;
}

.checkbox:last-child,
.radio:last-child {
  margin-bottom: 0;
}

.checkbox-inline,
.radio-inline {
  line-height: 1.8;
  margin-left: 0;
  margin-top: 5px;
  padding-left: 0;
}
.checkbox-inline .input-sm.form-control,
.checkbox-inline .input-group-sm > .form-control,
.checkbox-inline .input-group-sm > .input-group-btn > .form-control.btn,
.radio-inline .input-sm.form-control,
.radio-inline .input-group-sm > .form-control,
.radio-inline .input-group-sm > .input-group-btn > .form-control.btn {
  display: inline-block;
  font-size: 1em;
  width: auto;
  margin-left: 8px;
  margin-top: -3px;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-left: 16px;
  margin-top: 5px;
}

.checkbox-inline,
.radio-inline {
  line-height: 1.8;
  margin-left: 0;
  margin-top: 5px;
  padding-left: 0;
}
.checkbox-inline .input-sm.form-control,
.checkbox-inline .input-group-sm > .form-control,
.checkbox-inline .input-group-sm > .input-group-btn > .form-control.btn,
.radio-inline .input-sm.form-control,
.radio-inline .input-group-sm > .form-control,
.radio-inline .input-group-sm > .input-group-btn > .form-control.btn {
  font-size: 12px;
  margin-bottom: -5px;
  margin-top: -15px;
  vertical-align: middle;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-left: 16px;
  margin-top: 5px;
}

[type='checkbox']:checked,
[type='checkbox']:not(:checked) {
  left: -9999px;
  position: absolute;
}

[type='checkbox']:checked:disabled + span:before,
[type='checkbox']:not(:checked):disabled + span:before {
  background: #dfe1e3;
  border-color: #d2d5d7;
}
[type='checkbox']:checked:disabled + span,
[type='checkbox']:not(:checked):disabled + span {
  color: #c4c8cb;
}
[type='checkbox']:checked:disabled + span:after,
[type='checkbox']:not(:checked):disabled + span:after {
  content: '';
}
[type='checkbox']:checked:disabled + span:hover,
[type='checkbox']:not(:checked):disabled + span:hover {
  cursor: not-allowed;
}

[type='checkbox']:checked + span,
[type='checkbox']:not(:checked) + span {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  line-height: 20px;
  padding-left: 25px;
  position: relative;
  vertical-align: top;
}

[type='checkbox']:checked + span:before,
[type='checkbox']:not(:checked) + span:before {
  background: transparent;
  border-radius: 2px;
  content: '';
  left: 0;
  position: absolute;
  top: 0;
}

[type='checkbox']:checked + span:before {
  border-color: #b6b6b6;
  border-top: 0;
  border-left: 0;
}

[type='checkbox']:checked + span:after {
  color: $text-blue;
  content: '';
  font-family: $f-awesome;
  font-size: 13px;
  left: 3px;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='checkbox']:not(:checked) + span:after {
  content: '\f00c';
  font-family: $f-awesome;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type='checkbox']:checked + span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.checkbox-comment .form-control {
  margin: 0.5em 0;
}

.date-wrapper {
  position: relative;
  width: 140px;
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: middle;
}
.date-wrapper .form-control {
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  padding: 5px;
}
.date-wrapper .glyphicon-time:after {
  font-family: $f-awesome;
  content: '\f017';
}

.time-wrapper .rdtCounters {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: 0.5em;
  width: 70px;
}

.inline-date {
  max-width: 180px;
  float: right;
}
.inline-date .input-group-sm > .form-control {
  margin-top: 0;
}

.popover {
  border-radius: 3px;
  font-size: 1em;
}

.modal-content {
  border-radius: 3px;
}

.modal-header .svg-inline--fa {
  color: $text-grey;
  background: $white;
  padding: 10px;
  height: 35px;
  width: 35px;
  margin-right: 10px;
  text-align: center;
  line-height: 1;
  border-radius: 50%;
  font-size: 14px;
  border: 1px solid #cddfea;
  vertical-align: -0.725em;
}
.modal-header .close {
  font-family: $times;
  color: $white;
  font-size: 30px;
  font-weight: normal;
  text-shadow: none;
  opacity: 0.8;
}

.modal-title {
  font-size: 1.2em;
  text-transform: none;
  font-weight: 500;
}
.modal-title .fa {
  margin-right: 0.5em;
  color: #e4ecef;
}

.dialog {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.dialog .dialog-icon {
  color: #c4c8cb;
  font-size: 3em;
  flex: 1;
  text-align: center;
  padding-right: 15px;
}
.dialog .dialog-text {
  flex: 4;
}

.modal-footer .btn-link {
  margin-right: 10px;
  font-weight: normal;
}

.modal-sm .modal-body {
  padding: 3em 3em 3em 1em;
}
.modal-sm .modal-body h4 {
  text-transform: uppercase;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.modal-sm .modal-footer {
  padding: 0;
  display: flex;
}
.modal-sm .modal-footer > .btn {
  flex: 1;
  margin: 0;
  border: 0;
  border-radius: 0;
  padding: 15px 0;
  position: relative;
}
.modal-sm .modal-footer > .btn.btn-default {
  background: #dfe1e3;
}
.modal-sm .modal-footer > .btn.btn-default:hover {
  background: #d2d5d7;
}
.modal-sm .modal-footer > .btn .arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.6em;
}
.modal-sm .modal-footer > .btn .right {
  right: 15px;
}
.modal-sm .modal-footer > .btn .left {
  left: 15px;
}

.modal-danger h1,
.modal-danger h2,
.modal-danger h3,
.modal-danger h4,
.modal-danger h5,
.modal-danger h6 {
  color: $red;
}

.modal-danger .dialog-icon {
  color: #f2bcbf;
}

.modal-danger .btn-primary {
  background: $red;
}

@media (min-width: 992px) {
  .modal-dialog {
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%) !important;
  }
  .modal-content {
    box-shadow: 0 1px 5px rgba(0, 3, 2, 0.2);
  }
}

.progress {
  height: 24px;
  margin: 0;
}

.progress-bar {
  line-height: 24px;
  font-size: 12px;
  text-align: left;
  padding-left: 10px;
  background-color: $text-blue;
}

.pagination {
  font-family: $montserrat;
  font-weight: 500;
}
.pagination > li > a {
  padding: 6px 13px;
  font-size: 0.88em;
}
.pagination.react-bootstrap-table-page-btns-ul {
  margin: 0;
}

.btn-group .btn.dropdown-toggle {
  border: 0;
  padding: 0;
  letter-spacing: 0;
  text-transform: none;
  color: $text-blue;
}

.btn-group .btn .dropdown-menu > li > a {
  color: $text-grey;
}

.input-group .input-group-btn .btn {
  height: 40px;
  padding: 8px 15px;
  font-size: 12px;
}
.input-group .input-group-btn .btn .fa {
  margin: 0;
}

.video-js {
  width: 100%;
}

.card {
  background: $dark-bg;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  margin: 30px 0;
}

.card-body {
  padding: 25px 30px;
}
.card-body h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}
.card-body h3:first-child {
  margin-top: 10px;
}
.card-body h5 {
  margin: 10px 5px;
}

.card-heading {
  border-bottom: 1px solid #e4ecef;
  padding: 12px 30px;
  color: $white;
  font-weight: 400;
}
.card-heading h4 {
  font-weight: 400;
}
.card-heading small {
  font-family: $montserrat;
  display: block;
  color: #b5bdbf;
}
.card-heading .pre-heading {
  text-transform: uppercase;
  margin-bottom: 8px;
}
.card-heading .post-heading {
  margin-top: 10px;
}

.card-section {
  background: whitesmoke;
  border: 1px solid #e6e6e6;
  margin-bottom: 40px;
}
.card-section:last-child {
  margin-bottom: 0;
}

.dx {
  display: inline-block;
  height: 16px;
  width: 16px;
  vertical-align: middle;
}

.dx-remove {
  background: url(../images/ic-cross.svg);
  background-size: 100%;
}

.dx-filter {
  background: url(../images/ic-filter.svg);
  background-size: 100%;
  height: 15px;
  width: 24px;
  margin-left: 5px;
}

.type-video:before,
.type-audio:before,
.type-doc:before,
.type-txt:before,
.type-pdf:before,
.type-xls:before,
.type-other:before {
  color: $text-grey;
  font-family: $f-awesome;
  margin-right: 8px;
}

.type-video:before {
  content: '\f1c8';
}

.type-audio:before {
  content: '\f1c7';
}

.type-doc:before {
  content: '\f1c2';
}

.type-txt:before {
  content: '\f0f6';
}

.type-pdf:before {
  content: '\f1c1';
}

.type-xls:before {
  content: '\f1c3';
}

.type-other:before {
  content: '\f016';
}

.order {
  font-size: 1.1em;
}
.order .caret {
  color: $black;
}

.link-view {
  color: #2c3950;
  margin-left: 5px;
}

.btn-link.btn-round,
.btn-link.btn-round:focus {
  background: $white;
  border: 1px solid #d9d9d9;
  border-radius: 100px;
  color: $text-grey;
  display: inline-block;
  font-size: 14px;
  height: 30px;
  width: 30px;
  margin: 0;
  margin: -6px 2px;
  padding: 4px 8px;
  text-align: center;
}
.btn-link.btn-round:hover,
.btn-link.btn-round:focus:hover {
  border-color: $text-blue;
}
.btn-link.btn-round.btn-round-inverse,
.btn-link.btn-round:focus.btn-round-inverse {
  background: #141b2b;
  color: $white;
}
.btn-link.btn-round.view,
.btn-link.btn-round:focus.view {
  padding: 4px 6px;
}

.icon-tooltip {
  color: $text-grey;
  font-size: 14px;
  vertical-align: top;
}

.icon-edit {
  color: $text-grey;
  cursor: pointer;
}

.in-circle {
  border: 1px solid $text-grey;
  border-radius: 50%;
  display: inline-block;
  font-size: 14px;
  height: 35px;
  width: 35px;
  padding: 5px 10px;
  text-align: center;
}

.open-external {
  font-size: 2.4em;
  position: absolute;
  right: 25px;
  bottom: 15px;
}

.sub-header {
  background: $white;
}

.filter-group {
  flex: 100%;
  margin: 10px;
  margin-top: 16px;
}
.filter-group.dates,
.filter-group.asset,
.filter-group--2x {
  flex: 1 1 230px;
}
@media (min-width: 540px) {
  .filter-group {
    flex: 0 calc(50% - 20px);
  }
}
@media (min-width: 1080px) {
  .filter-group {
    flex: 0 calc(33% - 20px);
  }
}
@media (min-width: 1440px) {
  .filter-group {
    flex: 1;
  }
}

.filters.collapsed {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

.filters-container.collapsed {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}
.filters-container .filter-search {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  color: $text-blue;
  opacity: 1;
}
.filters-container .filter-input {
  transform: translateY(-29px);
  transition: all 0.3s ease;
  opacity: 0;
}
.filters-container .filter-input:hover {
  opacity: 0.5;
}
.filters-container .control-label {
  transition: all 0.3s ease;
}
.filters-container .label-container {
  position: relative;
  height: 27px;
  transform: translateY(0);
  transition: all 0.3s ease;
}
.filters-container .close-filter {
  position: absolute;
  top: 18px;
  right: 10px;
  z-index: 10;
  font-size: 20px;
  opacity: 0;
}
.filters-container .close-filter:hover {
  text-decoration: none;
}
.filters-container a {
  color: $text-grey;
  font-weight: normal;
}
.filters-container a.active {
  color: $text-blue;
  font-weight: bold;
}
.filters-container .filtering .close-filter {
  opacity: 1;
}
.filters-container .filtering .label-container {
  transform: translateY(-24px);
  transition: all 0.3s ease;
}
.filters-container .filtering .control-label {
  margin-bottom: 0;
  color: $text-blue;
  font-size: 12px;
  transform: translateY(0px);
  transition: all 0.3s ease;
}
.filters-container .filtering .filter-input {
  transform: translateY(0px);
  transition: all 0.3s ease;
  opacity: 0.9;
}
.filters-container .filtering .btn {
  margin-right: 5px;
  font-size: 12px;
}

.select-options [type='checkbox']:checked + span,
.select-options [type='checkbox']:not(:checked) + span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-group-sm > .input-group-btn > .btn {
  padding: 0;
}

.input-group .form-control:focus {
  z-index: 1;
}

.react-bs-table-search-clear-btn:after {
  z-index: 9;
  content: '\e5cd';
}

.react-bs-table-search-form:before {
  content: '\e8b6';
}

.react-bs-table-search-clear-btn {
  position: absolute;
  right: 30px;
  padding: 0;
  background: none;
  border: 0;
}
.react-bs-table-search-clear-btn > span {
  display: none;
}

.toolbar {
  padding: 20px 0;
}

.applied-filters {
  margin: 0 0 25px 0;
}

.applied-filters .applied-filters-heading {
  font-weight: 500;
  margin: 5px 5px 10px;
}

.applied-filters .applied-filters-heading a {
  padding-left: 10px;
  margin-left: 8px;
  font-size: 12px;
  text-transform: none;
  border-left: 1px solid #ccc;
}
.applied-filters > .label {
  position: relative;
  display: inline-block;
  padding: 10px 30px 10px 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: $white;
  font-size: 12px;
  background: $text-blue;
}
.applied-filters > .label strong {
  font-weight: normal;
}
.applied-filters > .label .remove-filter {
  position: absolute;
  top: 50%;
  right: 12px;
  display: inline-block;
  padding: 0;
  color: #ccc;
  font-size: 20px;
  line-height: 1;
  transform: translateY(-50%);
}
.applied-filters > .label .remove-filter:hover {
  color: $white;
  text-decoration: none;
}

.remove-filter {
  padding: 5px 0 0 8px;
  font-size: 1.2em;
  cursor: pointer;
}

.package-title {
  color: #141419;
  font-size: 1.2em;
  margin: 1em 0.5em;
}

.asset-table.react-bs-table-container .react-bs-table {
  border: 0;
  margin: 0;
}

.asset-table.react-bs-table-container .react-bs-container-header {
  background: none;
}

.asset-table.react-bs-table-container .react-bs-container-body {
  background: none;
}

.asset-table.react-bs-table-container thead {
  border-bottom: 1px solid #cfdadd;
}

.asset-table.react-bs-table-container .table > tfoot > tr.active td,
.asset-table.react-bs-table-container .table > thead > tr.active td,
.asset-table.react-bs-table-container .table > tbody > tr.active td {
  background: $white;
  color: $text-blue;
  font-weight: bold;
}

.asset-table.react-bs-table-container .table > tfoot > tr > th,
.asset-table.react-bs-table-container .table > tfoot > tr > td,
.asset-table.react-bs-table-container .table > thead > tr > th,
.asset-table.react-bs-table-container .table > thead > tr > td,
.asset-table.react-bs-table-container .table > tbody > tr > th,
.asset-table.react-bs-table-container .table > tbody > tr > td {
  padding: 12px 18px;
}

.asset-table.react-bs-table-container .table > tfoot > tr > th,
.asset-table.react-bs-table-container .table > thead > tr > th,
.asset-table.react-bs-table-container .table > tbody > tr > th {
  white-space: nowrap;
}

.asset-table.react-bs-table-container .table > tbody > tr > td {
  border-bottom: 1px solid #e6e6e6;
}

.asset-table.react-bs-table-container .no-edit {
  color: #8a8aa2;
  font-style: italic;
}

.asset-table.react-bs-table-container .Select .Select-control,
.asset-table.react-bs-table-container input,
.asset-table.react-bs-table-container select {
  background: $white;
  border-color: #e6e3e3;
  margin: -12px 0;
}

.upload-asset-wrapper {
  width: 800px;
  margin: auto;
}

.table-assets {
  background: #f5f5f5;
}
.table-assets .actions {
  font-size: 1em;
  text-align: right;
}
.table-assets .actions > a {
  padding: 0 0.5em;
}

.uploading {
  border-bottom: 1px solid #e6e6e6;
  padding: 15px 18px;
}

.upload-asset.visible-xs {
  border: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  text-transform: none;
}

.upload-asset {
  border: 3px dashed #cfdadd;
  border-radius: 5px;
  font-family: $montserrat;
  margin: 20px;
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
}
.upload-asset .fa-upload {
  color: #b5bdbf;
  font-size: 3em;
  margin-bottom: 20px;
}
.upload-asset .input-file {
  height: 0.1px;
  width: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.upload-asset .input-file + .control-label,
.upload-asset .input-file + .remove-me {
  color: $text-blue;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
}
.upload-asset .input-file + .control-label:hover,
.upload-asset .input-file + .remove-me:hover {
  border-bottom-width: 2px;
  color: $text-blue;
  cursor: pointer;
  margin-bottom: -1px;
}
.upload-asset .browse {
  border-bottom: 1px solid transparent;
  color: $text-blue;
  display: inline-block;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0;
}
.upload-asset .browse:hover {
  border-color: $text-blue;
  color: $text-blue;
  cursor: pointer;
}

.modal-assets .modal-body {
  margin: 0;
  padding: 0;
}

.modal-assets .submission {
  position: relative;
}
.modal-assets .submission h3 {
  background: #edf1f2;
  border-bottom: 1px solid #e3e2e2;
  border-top: 1px solid #e3e2e2;
  font-size: 1em;
  margin: 0;
  padding: 1em;
}
.modal-assets .submission h3 strong {
  color: #002a3a;
}
.modal-assets .submission .submission-body {
  padding: 2em;
}
.modal-assets .submission:after {
  content: '\f068';
  font-family: $f-awesome;
  position: absolute;
  right: 1em;
  top: 1em;
}

.select-all {
  margin-left: 10px;
}

.override-link {
  margin: 10px 0;
}

.modal-dialog.related-codes {
  width: 720px;
}
.modal-dialog.related-codes .table > thead > tr > th {
  text-transform: uppercase;
  font-family: $montserrat;
  padding: 10px;
  color: #141b2b;
}
.modal-dialog.related-codes .table > tbody > tr:last-child td:last-child {
  padding-top: 24px;
}
.modal-dialog.related-codes .table > tbody > tr > td {
  border: 0;
  padding: 15px 10px;
}
.modal-dialog.related-codes .table > tbody > tr > td:last-child .btn-sm,
.modal-dialog.related-codes .table > tbody > tr > td:last-child .btn-group-sm > .btn {
  padding: 8.5px 18px;
}

@-webkit-keyframes toastify-bounceInRight {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes toastify-bounceInRight {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.toast-enter--bottom-right,
.toast-enter--top-right,
.toastify-bounceInRight {
  -webkit-animation-name: toastify-bounceInRight;
  animation-name: toastify-bounceInRight;
}

@-webkit-keyframes toastify-bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes toastify-bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.toast-exit--bottom-right,
.toast-exit--top-right,
.toastify-bounceOutRight {
  -webkit-animation-name: toastify-bounceOutRight;
  animation-name: toastify-bounceOutRight;
}

@-webkit-keyframes toastify-bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes toastify-bounceInLeft {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }
  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.toast-enter--bottom-left,
.toast-enter--top-left,
.toastify-bounceInLeft {
  -webkit-animation-name: toastify-bounceInLeft;
  animation-name: toastify-bounceInLeft;
}

@-webkit-keyframes toastify-bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes toastify-bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.toast-exit--bottom-left,
.toast-exit--top-left,
.toastify-bounceOutLeft {
  -webkit-animation-name: toastify-bounceOutLeft;
  animation-name: toastify-bounceOutLeft;
}

@-webkit-keyframes toastify-bounceInUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes toastify-bounceInUp {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.toast-enter--bottom-center,
.toastify-bounceInUp {
  -webkit-animation-name: toastify-bounceInUp;
  animation-name: toastify-bounceInUp;
}

@-webkit-keyframes toastify-bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes toastify-bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.toast-exit--top-center,
.toastify-bounceOutUp {
  -webkit-animation-name: toastify-bounceOutUp;
  animation-name: toastify-bounceOutUp;
}

@-webkit-keyframes toastify-bounceInDown {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes toastify-bounceInDown {
  0%,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.toast-enter--top-center,
.toastify-bounceInDown {
  -webkit-animation-name: toastify-bounceInDown;
  animation-name: toastify-bounceInDown;
}

@-webkit-keyframes toastify-bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes toastify-bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }
  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.toast-exit--bottom-center,
.toastify-bounceOutDown {
  -webkit-animation-name: toastify-bounceOutDown;
  animation-name: toastify-bounceOutDown;
}

.toastify-animated {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.toastify {
  z-index: 2000;
  position: fixed;
  padding: 4px;
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  color: $white;
}
.toastify p {
  margin: 5px 0;
}
.toastify a {
  font-weight: bold;
  color: $red;
}

.toastify--top-left {
  top: 1em;
  left: 1em;
}

.toastify--top-center {
  top: 1em;
  left: 50%;
  margin-left: -300px;
}

.toastify--top-right {
  top: 1em;
  right: 1em;
}

.toastify--bottom-left {
  bottom: 1em;
  left: 1em;
}

.toastify--bottom-center {
  bottom: 1em;
  left: 50%;
  margin-left: -160px;
}

.toastify--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width: 480px) {
  .toastify {
    width: 100vw;
    padding: 0;
  }
  .toastify--top-center,
  .toastify--top-left,
  .toastify--top-right {
    left: 0;
    top: 0;
    margin: 0;
  }
  .toastify--bottom-center,
  .toastify--bottom-left,
  .toastify--bottom-right {
    left: 0;
    bottom: 0;
    margin: 0;
  }
}

.toastify__close {
  font-family: $times;
  padding: 0;
  color: $black;
  font-weight: lighter;
  font-size: 22px;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  opacity: 0.6;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
  line-height: 1;
}
.toastify__close:focus,
.toastify__close:hover {
  opacity: 1;
}

.toastify-content--default .toastify__close {
  color: $black;
  opacity: 0.3;
}
.toastify-content--default .toastify__close:hover {
  opacity: 1;
}

.toastify-content {
  position: relative;
  min-height: 48px;
  margin-bottom: 1rem;
  padding: 15px 20px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: $libre;
  color: $black;
  background: $white;
  cursor: pointer;
  font-weight: normal;
  padding-left: 75px;
}
.toastify-content .icon {
  font-size: 34px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  text-align: center;
}
.toastify-content strong {
  display: block;
  font-size: 1.25em;
  margin-top: 3px;
  font-family: $montserrat;
}

.toastify-content--default {
  background: $white;
  color: #aaa;
}

.toastify-content--info {
  background: #3498db;
}

.toastify-content--success {
  background: #edfce7;
}
.toastify-content--success .icon,
.toastify-content--success strong {
  color: #49b218;
}

.toastify-content--warning {
  background: #fff5eb;
}
.toastify-content--warning .icon,
.toastify-content--warning strong {
  color: #ff8f1f;
}

.toastify-content--danger {
  background: #fae6e7;
}
.toastify-content--danger .icon,
.toastify-content--danger strong {
  color: $red;
}

.toastify-content--info {
  background: #f4f7fd;
}
.toastify-content--info .icon,
.toastify-content--info strong {
  color: #4f7dd6;
}

.toastify-content--error {
  background: #e74c3c;
}

.toastify__body {
  margin: auto 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media only screen and (max-width: 480px) {
  .toastify-content {
    margin-bottom: 0;
  }
}

@-webkit-keyframes track-progress {
  0% {
    width: 100%;
  }
  to {
    width: 0;
  }
}

@keyframes track-progress {
  0% {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.toastify__progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 5px;
  z-index: 1;
  opacity: 0.7;
  -webkit-animation: track-progress linear 1;
  animation: track-progress linear 1;
  background-color: rgba(255, 255, 255, 0.7);
}
.toastify__progress--success {
  background: #49b218;
}
.toastify__progress--warning {
  background: #ff8f1f;
}
.toastify__progress--danger {
  background: $red;
}
.toastify__progress--info {
  background: #4f7dd6;
}

.toastify__progress--default {
  background: linear-gradient(90deg, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
}

.notify-success {
  background: #49b218;
}

#responses .responses thead {
  display: none;
}

#responses .responses tr[hidden] {
  display: table-row;
}

#responses .submissions {
  padding: 0;
  border: 0;
}
#responses .submissions table {
  width: 100%;
}
#responses .submissions table thead {
  display: table-header-group;
}
#responses .submissions table thead > tr > th {
  padding: 8px 12px;
  background: $white;
}
#responses .submissions table tbody > tr > td {
  padding: 12px;
  background: #f1f6f9;
  border-bottom: 0;
}
#responses .submissions table tbody > tr.status-row {
  border-bottom: 1px solid #eee;
}
#responses .submissions table tbody > tr.status-row td {
  padding: 0;
}
#responses .submissions table tbody > tr.status-row td div {
  padding: 12px;
}
#responses .submissions .react-bs-container-body,
#responses .submissions .react-bs-container-header {
  background: none;
}

#responses .header-row {
  background: #e4ecef;
  font-family: $montserrat;
  font-size: 1.1em;
}
#responses .header-row td {
  padding-bottom: 20px;
  padding-top: 20px;
}

#responses .destination-logo {
  max-height: 30px;
}

#responses .destination-logo-column {
  width: 100px;
  border-bottom: 0;
}

#responses .submission-row > td {
  padding: 0;
}

#responses .submission-id {
  width: 2em;
}

.response-container .table > thead {
  display: none;
}
.response-container .table > thead > tr > th {
  padding-bottom: 10px;
  padding-top: 10px;
  background: #fafcfd;
  border-bottom: 1px solid #d9dce3;
  color: $text-grey;
  font-family: $montserrat;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.response-container .table > tbody + tbody {
  border-top: 1px solid #d9dce3;
}
.response-container .table > tbody > tr > td {
  border: 0;
}
.response-container .table > tbody > tr.status-row > td {
  padding-top: 0;
}

.response-header > div {
  flex: 1;
}
.response-header .response-logo {
  display: flex;
  flex: 1;
}
.response-header .response-logo > img {
  margin-right: 20px;
}
.response-header .response-destination {
  padding-right: 20px;
  align-self: center;
}
.response-header .response-recipient {
  flex: 2;
}
.response-header .response-recipient i {
  margin-left: 10px;
  color: $text-grey;
}
.response-header .response-status {
  width: 180px;
  flex: initial;
}
.response-header .response-status > span {
  display: block;
  color: $text-grey;
  font-family: $libre;
  font-size: 13px;
}

.response-body .status-row .td {
  padding-top: 0;
}

.followup-modal {
  width: 700px;
}

.current-frame-comment {
  display: flex;
}

.current-frame-comment div {
  padding: 5px 15px;
}

.response-row {
  width: 50%;
}

.frames-row {
  width: 50%;
}

.responses-frame-image {
  max-width: 150px;
}

.followup-modal .select-all {
  color: #2c3950;
  font-weight: bold;
  margin-left: 0;
}
.recall-modal,
.reports-modal {
  max-width: 400px;
}
.recall-modal .modal-body,
.reports-modal .modal-body {
  padding: 20px 30px;
}
.recall-modal .modal-body h4,
.reports-modal .modal-body h4 {
  padding-bottom: 15px;
  text-transform: none;
  font-weight: 400;
}
.recall-modal .modal-body p,
.reports-modal .modal-body p {
  margin-bottom: 15px;
}
.recall-modal .select-all,
.reports-modal .select-all {
  color: #2c3950;
  font-weight: bold;
  margin-left: 0;
}

#messages {
  background: $black;
  border: 1px solid $dark-grey-border;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  margin: 20px 15px 30px;
  position: relative;
}
#messages .nav-tabs {
  background: $white;
}
#messages .nav-tabs > li > a {
  padding: 20px 35px;
}
#messages .tab-content {
  padding: 0;
}
#messages .messages {
  display: block;
  width: 100%;
  min-height: 400px;
  margin-bottom: 30px;
  overflow-y: auto;
}
@media (min-width: 992px) {
  #messages .messages {
    align-content: stretch;
    display: flex;
    max-height: 840px;
  }
}
#messages .messages-controls {
  padding: 20px 25px;
  overflow: hidden;
  color: $dark-grey;
  border-bottom: 1px solid $dark-grey-border;
}
#messages .messages-controls .ouic-form-input {
  max-width: 100%;
}

.ouic-button.secondary:hover {
  color: $main-blue;
}

#messages .messages-controls input {
  float: right;
  margin-top: -7px;
  width: 250px;
}
@media (min-width: 992px) {
  #messages .messages-controls input {
    display: inline-block;
    width: 300px;
  }
}
#messages .messages-list {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}
@media (min-width: 992px) {
  #messages .messages-list {
    width: 500px;
    max-height: none;
  }
}
#messages .messages-list .message {
  padding: 10px 15px;
  background: #f1f6f9;
  border-top: 1px solid $white;
  border-right: 1px solid #e4ecef;
  border-bottom: 1px solid #c4d5dc;
  border-left: 3px solid transparent;
}
@media (min-width: 992px) {
  #messages .messages-list .message {
    padding: 20px;
  }
}
#messages .messages-list .message:hover {
  cursor: default;
}
#messages .messages-list .message.unread .message-subject:before {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  content: '';
  background: $red;
  border-radius: 50%;
}
#messages .messages-list .message.active {
  background: $white;
  border-right-color: transparent;
  border-left-color: $text-blue;
  opacity: 1;
}
#messages .messages-list:-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

#messages .ouic-card {
  width: 100%;
}

#messages .ouic-card .ouic-card-header {
  display: block;
}

#messages .message-header {
  position: relative;
  font-family: $montserrat;
  font-weight: 500;
}
#messages .message-header .message-date {
  position: absolute;
  top: 0;
  right: 0;
  color: #b6b6b6;
  font-size: 12px;
}
#messages .message-header .has-attachment {
  margin-right: 5px;
  color: $text-grey;
  font-size: 14px;
}
#messages .message-header .message-subject {
  display: block;
  padding: 0;
  color: #2c3950;
  font-size: 16px;
}
@media (min-width: 992px) {
  #messages .message-header .message-subject {
    padding: 5px 0;
  }
}
#messages .message-header .message-preview {
  display: none;
  max-height: 3em;
  overflow: hidden;
  color: $text-grey;
  font-family: $libre;
  font-size: 12px;
}
@media (min-width: 992px) {
  #messages .message-header .message-preview {
    display: inline-block;
  }
}
#messages .message-header .message-preview:after {
  right: 0;
  bottom: 0;
  display: block;
  width: 1em;
  height: 10px;
  content: '...';
}
#messages .message-header .message-recipient,
#messages .message-header .message-sender {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
#messages .message-header .to {
  margin: 0 10px;
  color: $text-grey;
}
#messages .network {
  color: $grey-9b;
}
#messages .internal {
  color: $text-blue;
  font-weight: 400;
}
#messages .messages-container {
  align-content: stretch;
  flex: 1;
  overflow-y: auto;
  background: $black;
}
#messages .messages-container .message-header {
  position: relative;
  padding: 30px 30px 0;
}
@media (min-width: 992px) {
  #messages .messages-container .message-header {
    padding: 30px 50px 0;
  }
}
#messages .messages-container .message-header span {
  color: $main-blue;
  font-weight: normal;
}
#messages .messages-container .message-header .sender {
  margin-bottom: 0.5em;
}
#messages .messages-container .message-header .subject {
  margin-top: 1em;
}
#messages .messages-container .message-header .date {
  position: absolute;
  top: 25px;
  right: 35px;
  color: $text-grey;
  font-weight: normal;
}
#messages .messages-container .message-header .account {
  color: $text-blue;
}
#messages .message-content {
  padding: 30px;
}
@media (min-width: 992px) {
  #messages .message-content {
    padding: 35px 50px;
  }
}
#messages .message-content .body {
  margin-bottom: 3em;
}
#messages .message-content .date span,
#messages .message-content .recipient span,
#messages .message-content .sender span {
  display: inline-block;
  width: 70px;
  color: $text-grey;
  font-family: $montserrat;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}

ul.attachments {
  padding: 0;
  list-style: none;
}
ul.attachments > li {
  padding: 8px 0;
  font-family: $montserrat;
  font-weight: 500;
}
ul.attachments .type-audio:before,
ul.attachments .type-doc:before,
ul.attachments .type-pdf:before,
ul.attachments .type-txt:before,
ul.attachments .type-video:before,
ul.attachments .type-xls:before,
ul.attachments .type-other:before {
  float: left;
  width: 40px;
  height: 40px;
  padding: 6px 0;
  font-size: 18px;
  text-align: center;
  background: #f1f6f9;
  border: 1px solid #cddfea;
  border-radius: 50%;
}
ul.attachments .attachment-type {
  color: #b6b6b6;
  font-size: 12px;
  text-transform: uppercase;
}

.message-input-dropdown {
  background: #0f1317;
  border: 1px solid $dark-grey-border;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 0;
  color: $white;
  font-weight: bold;
  padding: 5px 0;
  position: absolute;
  right: 25px;
  top: 52px;
  width: 300px;
  z-index: 999;
}
.message-input-dropdown > div {
  padding: 5px 10px;
}
.message-input-dropdown > div span {
  font-weight: normal;
}
.message-input-dropdown > div:hover {
  background: $black;
  color: $main-blue;
  cursor: pointer;
}
.message-input-dropdown > div:hover:after {
  float: right;
  color: $main-blue;
  content: '\f061';
  font-family: $f-awesome;
}
.message-input-dropdown > div:hover span {
  color: $white;
  font-weight: bold;
}

.search-results-header {
  align-items: center;
  background: $dark-bg;
  border: 1px solid $dark-grey-border;
  display: flex;
  justify-content: space-between;
  margin: 1em 0 0.5em;
  padding: 1em 2em;
}
.search-results-header h4 {
  font-weight: normal;
  display: inline-block;
  margin-right: 1em;
}
.search-results-header strong {
  color: $text-blue;
}

.search-results-header a {
  display: inline-block;
  font-size: 18px;
}
.search-results-header a:after {
  padding-left: 1em;
  content: '|';
}
.search-results-header a:last-of-type:after {
  content: '';
}

#new-message .table tr td {
  display: block;
  width: 100%;
  vertical-align: middle;
}

#new-message .table tr td:nth-child(2n + 0),
#new-message .table > thead th:nth-child(2n + 0) {
  width: 150px;
}

#new-message .table > thead th:last-child {
  width: 35px;
}

#recipient-search {
  margin-left: 10px;
  vertical-align: bottom;
}

.new-message-attachments {
  margin-top: 20px;
}
.new-message-attachments .attachment {
  border-bottom: 1px solid #eee;
  padding: 5px 0;
  position: relative;
}
.new-message-attachments .attachment:last-child {
  border: 0;
}
.new-message-attachments .filename {
  font-family: $montserrat;
  font-weight: 500;
  padding-bottom: 5px;
}
.new-message-attachments .custom-select {
  display: inline-block;
  width: 140px;
  vertical-align: middle;
}
.new-message-attachments .remove-file {
  font-size: 24px;
  width: 25px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 50%;
  transform: translateY(-50%);
}
.new-message-attachments .card-section {
  margin-bottom: 10px;
}
.new-message-attachments .Select.Select--single > .Select-control .Select-value,
.new-message-attachments .Select .Select-control,
.new-message-attachments .Select .Select-input,
.new-message-attachments .Select .Select-placeholder {
  background: $white;
  height: 28px;
  line-height: 28px;
}
@media (min-width: 768px) {
  .new-message-attachments .filename {
    display: inline-block;
    width: calc(100% - 165px);
  }
  .new-message-attachments .remove-file {
    display: inline-block;
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    vertical-align: middle;
  }
}

.nav-tabs > li > a {
  text-transform: uppercase;
  font-family: $montserrat;
  font-weight: 500;
  color: $text-grey;
  padding-bottom: 15px;
  border: 0;
  border-bottom: 2px solid transparent;
}
.nav-tabs > li > a:hover {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  color: $text-blue;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus {
  border: 0;
  border-bottom: 2px solid $text-blue;
}
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus:hover {
  border: inherit;
  color: inherit;
  border: 0;
  border-bottom: 2px solid $text-blue;
}

.tab-content {
  padding: 20px;
}

.switch-view .btn-group .btn {
  border: 0;
  background: none;
  color: $text-grey;
  padding: 10px;
}

.dropdown-label {
  position: relative;
  display: inline-block;
}
.dropdown-label .dropdown-label-btn {
  background: $white;
  border: 1px solid $text-blue;
  color: $text-blue;
  display: inline-block;
  cursor: pointer;
  margin-right: 25px;
  font-size: 12px;
}
.dropdown-label .open + .dropdown-menu {
  display: inline-block;
}
.dropdown-label .dropdown-menu {
  padding: 10px 20px;
  min-width: 20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border: 1px solid rgba(0, 0, 0, 0.15);
  color: $dark-grey;
  max-width: 500px;
}
.dropdown-label .dropdown-menu:before {
  content: '';
  display: none;
}
.dropdown-label .dropdown-menu .form-group {
  margin-top: 15px;
}
.dropdown-label .dropdown-menu .checkbox-inline,
.dropdown-label .dropdown-menu .radio-inline {
  margin-right: 10px;
  text-align: left;
  font-size: 13px;
}
.dropdown-label .dropdown-menu .checkbox-inline + .checkbox-inline,
.dropdown-label .dropdown-menu .checkbox-inline + .radio-inline,
.dropdown-label .dropdown-menu .radio-inline + .checkbox-inline,
.dropdown-label .dropdown-menu .radio-inline + .radio-inline {
  margin-left: 0;
}

#label-from .checkbox-inline,
#label-from .radio-inline,
#label-account .checkbox-inline,
#label-account .radio-inline {
  width: calc(50% - 20px);
}

.dock-centered {
  width: 100%;
  position: absolute;
  left: 30%;
  top: 30%;
  display: relative;
}

.dock-centered-dialog {
  width: 800px;
  margin: 0 auto;
  border-radius: 7px;
  background: whitesmoke;
  margin: 30px auto 0;
  padding: 6px;
}
.checkbox-MultipleRecipientDialog {
  position: relative;
  width: 300px;
  display: inline-block;
}
.control-MultipleRecipientDialog {
  display: block;
}
.control-label-MultipleRecipientDialog {
  width: 250px;
  display: block;
}
.form-control-label-MultipleRecipientDialog {
  width: 200px;
  display: inline-block;
}
.form-group-MultipleRecipientDialog {
  display: block;
}
.contol-MultipleRecipientDialog {
  display: inherit;
}

.dock-centered-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.dock-modal {
  background: $black;
  border: 2px solid $dark-grey-border;
  bottom: 0;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.dock-modal .ouic-form-input {
  max-width: 100%;
  width: 100%;
}

.dock-modal.minimize .dock-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 100ms ease-in;
}

.dock-modal .ouic-panel-heading {
  position: relative;
}

.dock-modal.minimize .ouic-panel-body {
  padding: 0;
}

.dock-content {
  max-height: 1000px;
  transition: max-height 100ms ease-in;
}

.dock-modal-header {
  background: transparent;
  color: $white;
  font-family: $montserrat;
  font-weight: 500;
  padding: 14px 20px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
}

.dock-modal-header .fa {
  color: $white;
  padding: 0 8px;
}

.drag-and-drop-new-msg {
  padding: 0 20px 15px;
}
.dock-modal-body {
  padding: 0 20px;
}

.drag-and-drop-new-msg svg {
  display: none;
}

input.browse[type='file'] {
  display: none;
}

.drag-and-drop-new-msg .ouic-drag-and-drop {
  margin: 0;
  padding: 5px 0;
}

.drag-and-drop-new-msg .ouic-drag-and-drop p:first-of-type {
  padding: 0;
}

.drag-label {
  margin-bottom: 20px;
}

.dock-modal-footer {
  margin: 0 15px 20px;
}

@media (min-width: 768px) {
  .dock-modal {
    bottom: 0;
    box-shadow: -3px 0 5px rgba(0, 0, 0, 0.12);
    height: auto;
    width: 500px;
    left: auto;
    position: fixed;
    right: 0;
    top: auto;
  }
}

@media (min-width: 992px) {
  .dock-modal {
    width: 600px;
  }
}

.custom-select .Select-control {
  background: #f3f6f6;
  border-color: #dee5e7;
}

.custom-select.is-open > .Select-control,
.custom-select .Select-menu-outer {
  border-color: #e4eaec;
}

.custom-select.is-focused:not(.is-open) > .Select-control,
.custom-select:focus {
  border-color: #dee5e7;
  box-shadow: none;
  outline: 0;
}

.custom-select.Select--multi .Select-control,
.custom-select.Select--multi .Select .Select-input {
  height: 40px;
}

.custom-select.Select--multi .Select-multi-value-wrapper {
  line-height: 1;
}

.custom-select.Select--multi .Select-value {
  background: white;
  border-color: #bcccf0;
  border-radius: 20px;
  color: #646f89;
  font-family: $montserrat;
  font-weight: 500;
  line-height: 1;
  padding: 5px 20px 5px 5px;
  position: relative;
  margin-top: 3px;
}

.custom-select.Select--multi .Select-value-icon {
  border: 0;
  color: #6484cf;
  font-size: 18px;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.custom-select.Select--multi .Select-value-icon:hover,
.custom-select.Select--multi .Select-value-icon:focus {
  background: none;
  color: #2c3950;
}

#sendURL {
  display: none !important;
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: $white;
  border-radius: 4px;
  width: 278px;
  padding: 4px;
  margin-top: 1px;
  top: 100px;
  left: 20px;
  /* Calendars */
}
.daterangepicker:before,
.daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}
.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}
.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $white;
  border-left: 6px solid transparent;
}
.daterangepicker.opensleft:before {
  right: 9px;
}
.daterangepicker.opensleft:after {
  right: 10px;
}
.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}
.daterangepicker.opensright:before {
  left: 9px;
}
.daterangepicker.opensright:after {
  left: 10px;
}
.daterangepicker.dropup {
  margin-top: -5px;
}
.daterangepicker.dropup:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}
.daterangepicker.dropup:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid $white;
}
.daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3001;
}
.daterangepicker.single .ranges,
.daterangepicker.single .calendar {
  float: none;
}
.daterangepicker.show-calendar .calendar {
  display: block;
}
.daterangepicker .calendar {
  display: none;
  max-width: 270px;
  margin: 4px;
}
.daterangepicker .calendar.single .calendar-table {
  border: none;
}
.daterangepicker .calendar th,
.daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
  min-width: 32px;
}
.daterangepicker .calendar-table {
  border: 1px solid $white;
  padding: 4px;
  border-radius: 4px;
  background-color: $white;
}
.daterangepicker table {
  width: 100%;
  margin: 0;
}
.daterangepicker td,
.daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}
.daterangepicker td.week,
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: $white;
  border-color: transparent;
  color: #999;
}
.daterangepicker td.in-range {
  background-color: #f1f6f9;
  border-color: transparent;
  color: $black;
  border-radius: 0;
}
.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}
.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: $text-blue;
  border-color: transparent;
  color: $white;
}
.daterangepicker th {
  font-family: $montserrat;
  font-weight: 500;
}
.daterangepicker th.month {
  width: auto;
  text-transform: uppercase;
}
.daterangepicker td.disabled,
.daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}
.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  width: 40%;
}
.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.secondselect,
.daterangepicker select.ampmselect {
  width: 50px;
  margin-bottom: 0;
}
.daterangepicker .input-mini {
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  height: 30px;
  line-height: 30px;
  display: block;
  vertical-align: middle;
  margin: 0 0 5px 0;
  padding: 0 6px 0 28px;
  width: 100%;
}
.daterangepicker .input-mini.active {
  border: 1px solid #08c;
  border-radius: 4px;
}
.daterangepicker .daterangepicker_input {
  position: relative;
}
.daterangepicker .daterangepicker_input i {
  position: absolute;
  left: 8px;
  top: 8px;
}
.daterangepicker.rtl .input-mini {
  padding-right: 28px;
  padding-left: 6px;
}
.daterangepicker.rtl .daterangepicker_input i {
  left: auto;
  right: 8px;
}
.daterangepicker .calendar-time {
  text-align: center;
  margin: 5px auto;
  line-height: 30px;
  position: relative;
  padding-left: 28px;
}
.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.ranges {
  font-size: 11px;
  float: none;
  margin: 4px;
  text-align: left;
}
.ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.ranges li {
  font-size: 13px;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  color: #08c;
  padding: 3px 12px;
  margin-bottom: 8px;
  cursor: pointer;
}
.ranges li:hover {
  background-color: #08c;
  border: 1px solid #08c;
  color: $black;
}
.ranges li.active {
  background-color: #08c;
  border: 1px solid #08c;
  color: $white;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }
  .daterangepicker .ranges ul {
    width: 160px;
  }
  .daterangepicker.single .ranges ul {
    width: 100%;
  }
  .daterangepicker.single .calendar.left {
    clear: none;
  }
  .daterangepicker.single.ltr .ranges,
  .daterangepicker.single.ltr .calendar {
    float: left;
  }
  .daterangepicker.single.rtl .ranges,
  .daterangepicker.single.rtl .calendar {
    float: right;
  }
  .daterangepicker.ltr {
    direction: ltr;
    text-align: left;
  }
  .daterangepicker.ltr .calendar.left {
    clear: left;
    margin-right: 0;
  }
  .daterangepicker.ltr .calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker.ltr .calendar.right {
    margin-left: 0;
  }
  .daterangepicker.ltr .calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker.ltr .left .daterangepicker_input {
    padding-right: 12px;
  }
  .daterangepicker.ltr .calendar.left .calendar-table {
    padding-right: 12px;
  }
  .daterangepicker.ltr .ranges,
  .daterangepicker.ltr .calendar {
    float: left;
  }
  .daterangepicker.rtl {
    direction: rtl;
    text-align: right;
  }
  .daterangepicker.rtl .calendar.left {
    clear: right;
    margin-left: 0;
  }
  .daterangepicker.rtl .calendar.left .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .daterangepicker.rtl .calendar.right {
    margin-right: 0;
  }
  .daterangepicker.rtl .calendar.right .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .daterangepicker.rtl .left .daterangepicker_input {
    padding-left: 12px;
  }
  .daterangepicker.rtl .calendar.left .calendar-table {
    padding-left: 12px;
  }
  .daterangepicker.rtl .ranges,
  .daterangepicker.rtl .calendar {
    text-align: right;
    float: right;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }
  .daterangepicker.ltr .ranges {
    float: left;
  }
  .daterangepicker.rtl .ranges {
    float: right;
  }
  .daterangepicker .calendar.left {
    clear: none !important;
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loading {
  position: relative;
}
.loading .btn {
  opacity: 0.64;
  transition: all 0.3s ease;
}

.loading .spinner {
  display: block;
}

.spinner-overlay {
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}
.spinner-overlay .spinner {
  height: 100px;
  width: 100px;
  top: 50%;
  transform: translateY(-50%);
}

.spinner {
  height: 20px;
  width: 20px;
  margin: 0 auto;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
  background-color: $white;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.refreshing {
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 40%;
  transform: translateY(-50%);
}
.refreshing .spinner {
  height: 50px;
  width: 50px;
}
.refreshing .double-bounce1,
.refreshing .double-bounce2 {
  background-color: #9eb3e1;
}

.reload-box {
  display: block;
  padding: 15px;
  margin-bottom: 5px;
  margin-top: 5px;
  top: 50%;
}
.reload-box .spinner {
  height: 50px;
  width: 50px;
}
.reload-box .double-bounce1,
.reload-box .double-bounce2 {
  background-color: #9eb3e1;
}

.page-not-found {
  width: 100%;
  text-align: center;
  padding: 5em 0;
  max-width: 500px;
  margin: 0 auto;
}
.page-not-found .fa-exclamation-triangle {
  color: #e4ecef;
  font-size: 20em;
}
.page-not-found h1 {
  color: $text-grey;
  font-size: 10em;
  position: relative;
}
.page-not-found h2 {
  font-size: 2em;
  text-transform: uppercase;
  color: #c4c8cb;
  margin: 0 0 2em;
}
.page-not-found .btn-link {
  text-transform: uppercase;
  color: $text-blue;
}

#asset-page {
  padding-top: 30px;
}

.top-pane {
  padding: 2em 0;
  color: #f1f6f9;
  background: #141b2b;
}

.viewer {
  display: table;
  width: 100%;
  margin-bottom: 1em;
}

.disabled-player {
  background-color: gray;
  position: relative;
  display: table-cell;
  width: 1000px;
  /* Aspect ratio 16:9 */
  padding-top: 56.25%;
}

.asset-player {
  position: relative;
  display: table-cell;
  width: 100%;
  height: 100%;
  text-align: center;
}
.asset-player img {
  width: 100%;
}

.asset-table.react-bs-table-container .table > tbody > tr > td:first-child:before,
.asset-table.react-bs-table-container .table > thead > tr > td:first-child:before {
  margin-right: 10px;
  content: '\f28d';
  font-family: $f-awesome;
}

.asset-table.react-bs-table-container .table > tbody > tr > td:last-child,
.asset-table.react-bs-table-container .table > thead > tr > td:last-child {
  width: 80px;
}

.asset-table.react-bs-table-container .table > tbody > tr.active td,
.asset-table.react-bs-table-container .table > thead > tr.active td {
  color: $text-blue;
  font-weight: bold;
  background: $white;
}
.asset-table.react-bs-table-container .table > tbody > tr.active td:first-child:before,
.asset-table.react-bs-table-container .table > thead > tr.active td:first-child:before {
  content: '\f144';
}

.attachments {
  margin-top: 2em;
}
.attachments h5 {
  margin-bottom: 1em;
}

.now-playing .info {
  display: inline-block;
  margin-top: 10px;
  margin-right: 30px;
  color: $text-grey;
  text-align: left;
}
.now-playing .label {
  padding: 0;
  color: $grey-9b;
}

.requested-materials {
  margin: 1em 0;
}
.requested-materials .requested-item {
  padding: 0.25em 0.5em;
  line-height: initial;
  font-weight: bold;
  color: $text-grey;
}
.requested-materials .requested-item .close {
  float: none;
  padding: 0;
  margin: 5px 7px;
  vertical-align: middle;
  font-size: 20px;
}
.requested-materials .requested-item:last-child {
  border-bottom: 0;
}

textarea.additional-reviewers {
  height: 155px;
}

.panel-collapsible {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
  border-color: #e4ecef;
}

.panel-collapsible .panel-heading {
  align-items: center;
  background: #f1f6f9;
  background: $white;
  border-bottom: 1px solid #e4ecef;
  border-top: 1px solid #e4ecef;
  color: #002a3a;
  display: flex;
  font-family: $montserrat;
  padding: 1em 2em;
}
.panel-collapsible .panel-heading h4 {
  display: inline-block;
  font-weight: 400;
}
.panel-collapsible .panel-heading:hover {
  cursor: pointer;
}
.panel-collapsible .panel-heading:before {
  display: inline-block;
  width: 10px;
  margin-right: 0.5em;
  color: #2c3950;
  content: '\f107';
  font-family: $f-awesome;
}
.panel-collapsible .panel-body {
  padding: 1em 2em;
}
.panel-collapsible.collapsed .panel-heading:before {
  content: '\f105';
}

.panel-collapsible .panel-addon .form-control,
.panel-collapsible .panel-addon .Select {
  display: inline-block;
  vertical-align: middle;
  width: 200px;
}

.panel-collapsible .panel-addon .btn-sm,
.panel-collapsible .panel-addon .btn-group-sm > .btn {
  letter-spacing: 0;
}

.frame-comment:last-child {
  border-bottom: 0;
}
.frame-comment .comment {
  display: inline-block;
  width: calc(100% - 230px);
  margin: 0.5em 0 0 1.5em;
}
.frame-comment .comment p {
  margin: 1em 0;
}
.frame-comment .comment textarea {
  height: 100%;
}
.frame-comment .comment .action-buttons {
  margin-top: 0.25em;
}
.frame-comment .comment .action-buttons .btn {
  margin-bottom: 0;
}
.frame-comment .btn .fa {
  color: $text-blue;
  margin: 0;
}
.frame-comment .btn-group {
  margin: 0;
  float: right;
}
.frame-comment .btn-group .dropdown-toggle {
  padding: 0 10px;
}

.inner-form {
  margin-bottom: 15px;
}
.inner-form .form-control {
  background: $white;
}

.applied-filters .filterType-recipients {
  background-color: #3f51b5;
}

.applied-filters .filterType-account {
  background-color: #2196f3;
}

.applied-filters .filterType-name,
.applied-filters .filterType-product {
  background-color: #00bcd4;
}

.applied-filters .filterType-agency {
  background-color: #009688;
}

.applied-filters .filterType-unifiedStatus,
.applied-filters .filterType-status {
  background-color: #4caf50;
}

.applied-filters .filterType-created-by {
  background-color: #ffc107;
}

.applied-filters .filterType-dateSubmitted {
  background-color: #ff9800;
}

.applied-filters .filterType-dateReviewed {
  background-color: #ff5722;
}

.applied-filters .filterType-isci {
  background-color: #9c27b0;
}

.applied-filters .filterType-recipient {
  background-color: #673ab7;
}

.applied-filters .filterType-movieName {
  background-color: #d50000;
}

.applied-filters .filterType-other {
  background-color: #757575;
}

.applied-filters .filterType-From {
  background-color: #3f51b5;
}

.applied-filters .filterType-To {
  background-color: #2196f3;
}

.applied-filters .filterType-Destination {
  background-color: #00bcd4;
}

.filterLegend-From {
  border-top: 2px solid #3f51b5;
}

.filterLegend-To {
  border-top: 2px solid #2196f3;
}

.filterLegend-Destination {
  border-top: 2px solid #00bcd4;
}

.filterLegend-recipients {
  border-top: 2px solid #3f51b5;
}

.filterLegend-account {
  border-top: 2px solid #2196f3;
}

.filterLegend-name,
.filterLegend-product {
  border-top: 2px solid #00bcd4;
}

.filterLegend-agency {
  border-top: 2px solid #009688;
}

.filterLegend-unifiedStatus,
.filterLegend-status {
  border-top: 2px solid #4caf50;
}

.filterLegend-created-by {
  border-top: 2px solid #ffc107;
}

.filterLegend-dateSubmitted {
  border-top: 2px solid #ff9800;
}

.filterLegend-dateReviewed {
  border-top: 2px solid #ff5722;
}

.filterLegend-isci {
  border-top: 2px solid #9c27b0;
}

.filterLegend-recipient {
  border-top: 2px solid #673ab7;
}

.filterLegend-movieName {
  border-top: 2px solid #d50000;
}

.filterLegend-other {
  border-top: 2px solid #757575;
}

.custom-select.Select .Select-control {
  height: 40px;
  line-height: 38px;
  border-radius: 0;
}

.custom-select.Select .Select-placeholder,
.custom-select.Select .Select--single > .Select-control .Select-value {
  line-height: 38px;
}

.custom-select.Select.is-disabled > .Select-control {
  border-color: #f0f2f4;
  opacity: 0.9;
}
.custom-select.Select.is-disabled > .Select-control .Select-placeholder,
.custom-select.Select.is-disabled > .Select-control .Select--single > .Select-control .Select-value {
  color: #d9dce3;
}

.custom-select.Select.is-disabled.has-value.Select--single > .Select-control .Select-value .Select-value-label {
  color: #d9dce3;
}

.claims {
  max-height: 600px;
  overflow: auto;
}
.claims .checkbox-inline {
  margin: 0;
  line-height: 1.5;
}
.claims > tbody > tr > td,
.claims > tbody > tr > th,
.claims > thead > tr > td,
.claims > thead > tr > th {
  vertical-align: middle;
}

.toolbar {
  padding-top: 0;
}
.toolbar .form-control-inline,
.toolbar .Select {
  display: inline-block;
  width: 100%;
  margin-right: 10px;
  vertical-align: middle;
}

.substantiation {
  margin-top: 1.5em;
}
.claim-subst-ctrl {
  width: 49%;
  float: right;
  margin: 0;
  padding: 7pt 0 6pt 0;
}
.claim-subst-btn {
  width: 49%;
  padding-left: 0;
  padding-right: 0;
}

.centered-placeholder {
  padding: 11px;
  margin: auto;
  text-align: center;
  color: #868686;
}

.font15 {
  font-size: 15px;
}
.edit {
  cursor: pointer;
}

.gtmSignIn,
.gtmSendResetLink,
.gtmFYP,
.gtmCancelFYP {
  /*background-color:#BADA55*/
}

.videowindow,
.popout,
.popoutVideo {
  background-color: black;
}
.popout div .video-js {
  height: calc(100vh - 20px);
}
.popoutVideo div .video-js {
  height: calc(100vh - 60px);
}
.search-link {
  margin-left: 15px;
  margin-right: 15px;
}

.searchIcon {
  margin-right: 0px !important;
}

.btn {
  padding: 10px 10px;
}

.applyBtn {
  color: $white;
  background-color: $text-blue;
  border-color: $text-blue;
  border-top-color: rgb(100, 133, 207);
  border-right-color: rgb(100, 133, 207);
  border-bottom-color: rgb(100, 133, 207);
  border-left-color: rgb(100, 133, 207);
}
input[type='search']::-webkit-search-cancel-button {
  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;

  /* icon size */
  background-size: 14px;
}

/* UTILS */

.text-center {
  text-align: center;
}

.mt-small {
  margin-top: 13px;
}

.mr-small {
  margin-right: 13px;
}

.ml-medium {
  margin-left: 13px;
}

.ml-small {
  margin-left: 3px;
}

.px-small {
  padding: 0 3px;
}

.d-ib {
  display: inline-block;
}

.icon-container {
  display: inline-block;
  width: 18px;
  height: 100%;
}

/* PANELS */
.frame_panel_default {
  padding: 25px;
  text-align: center;
}

.ratings-panel__utils {
  align-items: center;
  display: flex;
  position: absolute;
  right: 4%;
  margin-top: -19px;
  max-width: 60%;
}
.ratings-panel__utils .btn {
  margin-left: 5px;
}

.bigger .public-DraftEditor-content {
  min-height: 110px;
}

.smaller .public-DraftEditor-content {
  min-height: 50px;
}

/* FRAMES */

.frame--empty {
  background-color: #efefef;
  height: 100%;
  width: 100%;
}

/* ASSET TABLE */

.file-name-column div {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

/* DASHBOARD */
.ad-code-copy {
  cursor: pointer;
  padding-left: 5px;
}
.ad-code-copy-input {
  position: absolute;
  left: -999999px;
}

@media (min-width: 992px) {
  .response-row {
    width: 60%;
  }

  .frames-row {
    width: 40%;
  }
}

.vjs-resize-manager {
  border: none;
  height: 0px;
}

/* NOTIFICATIONS */

div.toastify-content--error {
  color: $red;
  background-color: #fae6e7;
}

.toastify__body > div > p {
  color: $dark-grey;
}

.toastify-content--error > .toastify__body > div > p > strong {
  color: $red;
}

.related-codes-body {
  max-height: 80vh;
  overflow-y: scroll;
  position: relative;
  padding: 15px;
}

/** DatagridItem **/

.file-container {
  display: flex;
  alig-items: center;
}

.related-codes-modal .modal-body {
  max-height: 80vh;
  overflow-y: scroll;
}
/** Reviewer Dashboard Checkboxes */
.checkbox--personalized .checkbox {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
}

.checkbox--personalized input[type='checkbox']:checked + span.checkbox-indeterminate:after {
  border: 1px solid $text-blue;
  content: '';
  display: block;
  height: 11px;
  margin: 2px 7px;
  border-width: 0 3px 0 0;
  transform: rotate(90deg);
  left: 1px;
  top: 2px;
}

.checkbox-header-container {
  display: flex;
  justify-content: flex-end;
}
button.checkbox-header-options.btn.btn-xs.btn-link,
button.checkbox-header-options.btn.btn-xs.btn-link:focus {
  margin: -2px;
  padding: 0;
}

.checkbox-header-options i.fa.fa-angle-down {
  margin-right: 0px;
  font-size: 1.1em;
}

.header-options-icon {
  pointer-events: none;
}

.submission-filename {
  display: flex;
  margin-right: 3px;

  .ouic-form-input {
    z-index: 3;
  }

  .ouic-form-input input:focus {
    min-width: 300px;
    z-index: 10;
  }
}

.submission-filename span {
  margin-left: 5px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.is-link {
  text-decoration: underline;
  cursor: pointer;
}

@media (min-width: 1441px) {
  button.checkbox-header-options.btn.btn-xs.btn-link,
  button.checkbox-header-options.btn.btn-xs.btn-link:focus {
    margin: -3px;
    padding: 0;
  }
}

/** Table Row - Draft Disabled */
.tr-draft .checkbox--personalized {
  display: none;
}

/** MODALS */

#multipleReviewFooter {
  justify-content: flex-end;
  padding: 15px;
}

#multipleReviewFooter button {
  flex: 0;
  padding: 15px;
}

#multiple-recipients-tooltip {
  width: 400px;
}

#multiple-recipients-tooltip .tooltip-inner {
  max-width: none;
  width: 100%;
}

.btn.btn-info.btn-xs.textarea-save-btn {
  display: none;
}

.input-editor {
  position: absolute;
}

.checkboxes_container {
  display: flex;
  flex-wrap: wrap;

  .ouic-checkbox {
    margin-bottom: 7px;
  }
}

.checkbox--text-off {
  font-size: 12px;
  font-weight: light;
  color: #9fa8ba;
}

.ouic-dropdown__control {
  width: 100%;
}

.ouic-dropdown--has-error.ouic-dropdown .ouic-dropdown__control {
  border-color: #e31f2e !important;
}

.hasError input {
  border-color: #e31f2e !important;
}

.datepicker-light {
  background: $light-bg;
  .flatpickr-months, .flatpickr-prev-month, .flatpickr-next-month, .flatpickr-current-month, .flatpickr-day {
    color: $black!important;
    svg {
      fill: $black!important;
    }
  }
  .flatpickr-day {
    &:hover {
      background: $main-blue;
      box-shadow: none;
      color: $grey-e;
      border-color: $main-blue;
    }
  }
}