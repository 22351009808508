//FONTS
$f-awesome: "FontAwesome";
$libre: "Libre Franklin", sans-serif;
$menlo: Menlo, Monaco, Consolas, "Courier New", monospace;
$monospace: monospace, sans-serif;
$montserrat: "Montserrat", sans-serif;
$sans: sans-serif;
$times: "Times New Roman", sans-serif;

//Colors
$white: #fff;
$black: #000;
$grey: #808080;

$main-blue: #1F72F2;
$light-blue: #6485cf;
$text-blue: #6485cf;
$dark-grey: #333;

$text-grey: #657089;

$dark-grey-border: #2B3441;
$grey-bd: #434C5C;
$grey-9b: #9B9B9B;


$dark-bg: #1B1E23;
$dark-bg2: #0F1418;

$inactive-grey: #4A4D55;
$red: #D22932;

//LIGHT-THEME

$bg-footer: #1b2730;
$bg-header: #2c3950;
$light-bg: #f2f4f6;

$blue-grey: #f1f6f9;
$blue-grey--dark: #d4eefd;

$grey-e: #eee;

$box-shadow: 0 2px 5px rgba(0, 0, 0, 0.12);
